import React from 'react'
import { useTranslation } from 'react-i18next'
import ReportChart from './ReportChat'

const ReportDetails = () => {
    const { t } = useTranslation()

    return (
        <div>
            <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                {t('Reports')} : {t('Demo_Report')}
            </h3>
            <h4 className='text-md mt-[10px] font-semibold text-[#5a7bad] '>This is only a demo</h4>
            <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-6 gap-4'>
                <div className='bg-[#f6fbff] w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                    <div className='flex gap-[15px]'>
                        <div>
                            <h5 className='text-[#599bfb] font-medium text-xl'>{t('Average')}</h5>
                            <h5 className='text-[#5a7bad] text-2xl mt-1
                             font-medium'>8.1295933170732</h5>
                        </div>
                    </div>
                </div>
                <div className='bg-[#f6fbff] w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                    <div className='flex gap-[15px]'>
                        <div>
                            <h5 className='text-[#599bfb] font-medium text-xl'>{t('Maximum')}</h5>
                            <h5 className='text-[#5a7bad] text-2xl mt-1
                             font-medium'>12.416666</h5>
                        </div>
                    </div>
                </div>
                <div className='bg-[#f6fbff] w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                    <div className='flex gap-[15px]'>
                        <div>
                            <h5 className='text-[#599bfb] font-medium text-xl'>{t('Minimum')}</h5>
                            <h5 className='text-[#5a7bad] text-2xl mt-1
                             font-medium'>4.883333</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <div className="bg-[#f6fbff] w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl">
                    <ReportChart />
                </div>
            </div>
            <p className="mt-3 text-[#5a7bad]">This Demo Report shows the hourly data from 2021-11-19 to 2021-11-23.
                It is calculated by averaging the values in the same group. The average is 8.1295933170732. The maximum is 12.416666 in group Group 1 on 2021-11-19.
                The minimum is 4.883333 in group Group 1 on 2021-11-19. </p>
        </div>
    )
}

export default ReportDetails