/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { API } from '../../../api'
import { localStorageGet } from '../../../utils/localstorage'
import jsonData from '../../Json/CardJson.json'
const GroupList = ({ groupArray, setGroupArray }) => {
    const [ deviceOBJ, setDevice ] = useState({
        device : '',
        gauge : '',
        order_key: '',
        group_as:'',
        show_latest:'',
        show_daily_hours:'',
        line_color:'',
        mode:'',
        iot_host:'',
        iot_type:'',
        slavid_id: '',
    })
    const { t } = useTranslation()

    const [ siteData, setSiteData ] = useState([])
    const [ deleteDevice, setDeleteDevice ] = useState(true)
    const [ id, setID ] = useState(null)
    const [ gauge, setGauge ] = useState([])
    const [ profile, setProfile ] = useState([])

    const userType = localStorageGet('type')

    const deleteGroup = (id) => {
        setGroupArray(groupArray.filter((val, i) => i !== id))
    }

    const handleOnchangeLabel = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.label = val
            }
            return value
        }))
    }

    const getProfile = async() =>{
        const response = await API('/api/lists/notification')
        // console.log(response.notification)
        setProfile(response.notification)
    }

    // const handleOnChangeProfile = (e) =>{
    //     setProfile(e)
    // }

    const handleOnChangeNotification = (val, id) =>{
        setGroupArray(groupArray.map((value, i) =>{
            if(i === id){
                value.notification_condition = val
            }
            return value
        }))
    }

    const handleOnchangeEvalution = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.evaluation = val
            }
            return value
        }))
    }

    const handleOnchangeUnit = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.unit = val
            }
            return value
        }))
    }

    const handleOnchangeType = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.type = val
            }
            return value
        }))
    }

    const handleOnchangeBackground = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.color = val
            }
            return value
        }))
    }

    const handleOnChange = (val, id, type) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value[ type ] = val
            }
            return value
        }))
    }

    const handleOnchangeDefault_line_color = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.default_line_color = val
            }
            return value
        }))
    }

    const handleOnchangeDiff = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                if(val){
                    value.isDiff = false
                } else {
                    value.isDiff = true
                }
            }
            return value
        }))
    }

    const handleOnchangeAccumulative = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                if(val){
                    value.accumulative = false
                } else {
                    value.accumulative = true
                }
            }
            return value
        }))
    }

    const handleOnchangeToZeroValues = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                if(val){
                    value.removeZeroValues = false
                } else {
                    value.removeZeroValues = true
                }
            }
            return value
        }))
    }

    const handleChangeFromDate = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.fromDate = val
            }
            return value
        }))
    }

    const handleChangeToDate = (val, id) => {
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.toDate = val
            }
            return value
        }))
    }

    const getDatas = async () => {
        try{
            const dropdownData = []
            const sites = await API('/api/site/lists')
            if(userType === 'super-admin'){
                sites.sites.map((val) => val.floors.map((flr) =>
                    dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, device: flr.devices })
                ))
                setSiteData(dropdownData)
            } else {
                const sitesData = JSON.parse(localStorageGet('sites'))
                const siteslist = sites.sites.filter((val) => sitesData.includes(val.name))

                siteslist.map((val) => val.floors.map((flr) =>
                    dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, device: flr.devices })
                ))
                setSiteData(dropdownData)
            }

            const gauges = await API('/api/lists/gauge')
            setGauge(gauges.gauge)
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        }
    }

    const deleteDeviceData = (groupId, i) => {
        const data = groupArray.map((value, index) => {
            if(index === groupId){
                value.devices = value.devices.filter((val, ind) => ind !== i)
            }
            return value
        })

        setGroupArray(data)
    }

    const handleOnclickDevice = (id) => {
        setID(id)
        if(deleteDevice){
            setDeleteDevice(false)
        }
        setGroupArray(groupArray.map((value, i) => {
            if(i === id){
                value.devices = [ ...value.devices, deviceOBJ ]
            }
            return value
        }))
        setDevice({
            device : '',
            gauge : '',
            order_key: '',
            group_as:'',
            show_latest: '',
            show_daily_hours:'',
            line_color:'',
            mode:'',
            iot_host:'',
            iot_type:'',
            slavid_id: '',
        })
    }

    const selectDevice = (db, ind, deviceID) => {
        const deviceData = siteData.map((site) => {
            return site.device.filter((device) => device.name === db)
        }).flat()
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        device.device = deviceData[ 0 ].name,
                        device.iot_host = deviceData[ 0 ].iotHost,
                        device.iot_type = deviceData[ 0 ].iotType,
                        device.slavid_id = deviceData[ 0 ].iotId
                        device.group_as = deviceData[ 0 ].group_as
                        device.show_latest = deviceData[ 0 ].show_latest
                        device.show_daily_hours = deviceData[ 0 ].show_daily_hours
                        device.line_color = deviceData[ 0 ].line_color
                        device.mode = deviceData[ 0 ].mode
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    const selectGauge = (db, ind, deviceID) => {
        console.log(db, ind, deviceID)
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        device.gauge = db
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    const selectColor = (db, ind, deviceID) => {
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        console.log('sss')
                        device.line_color = db
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    const selectGroup = (db, ind, deviceID) =>{
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        device.group_as = db
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    const selectLatest = (db, ind, deviceID) =>{
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        device.show_latest = (db == 'true' ? true : false)
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    const selectDailyHours = (db, ind, deviceID) => {
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        device.show_daily_hours = (db == 'true' ? true : false)
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    const selectMode = (db, ind, deviceID) =>{
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        device.mode = db
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    const handleOnclickNotification =(ind)=>{
        setGroupArray(groupArray.map((val, i) =>{
            if(i === ind){
                val.notificationDisplay = !val.notificationDisplay
            }
            return val;
        }))
    }

    const selectType = (db, ind, deviceID) => {
        const data = groupArray.map((value, i) => {
            if(i === ind){
                value.devices.map((device, indx) => {
                    if(indx === deviceID){
                        device.order_key = db
                    }
                })
            }
            return value
        })
        setGroupArray(data)
    }

    useEffect(() => {
        getDatas()
        getProfile()
    }, [])

    return (
        <>
            {groupArray.map((val, i) => <div key={ i } className='bg-white p-[24px] border-[1px] border-gray-300 rounded-[8px]'>
                <h3 className="text-xl leading-6 font-bold text-[#5a7bad] ">{t('Group')} #{i + 1} {t('Name')}</h3>
                <div className='mt-[10px] md:flex gap-3'>
                    <div className='md:w-[25%]'>
                        <input id="name" value={ val.label } onChange={ (e) => handleOnchangeLabel(e.target.value, i) } placeholder={ t('label') } type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                    </div>
                    <div className='md:w-[75%] md:mt-0 mt-6'>
                        <div className='overflow-x-auto'>
                            <table className='md:w-full w-[627px] overflow-x-auto lg:w-full divide-y divide-gray-200 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-[15px]'>
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            #
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('DEVICE')}
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('GAUGE')}
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('ORDER_KEY')}
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('Group_As')}
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('Mode')}
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('ShowLatest')}
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('ShowDailyHours')}
                                        </th>
                                        <th scope="col" className="xl:px-6 px-3 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            {t('line_color')}
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only"></span>
                                        </th>
                                    </tr>
                                </thead>
                                {val.devices?.map((dvc, ind) =>
                                    <tbody key={ ind } className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                                {t('ITEM')} { ind + 1 }
                                            </td>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <select
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                            id="device"
                                                            name="device"
                                                            value={ dvc.device }
                                                            onChange={ (e) => selectDevice(e.target.value, i, ind) }
                                                        >
                                                            <option value="" disabled selected>---</option>
                                                            {siteData?.map((valSite) => {
                                                                return(
                                                                    <>
                                                                        <option key={ valSite.id } disabled>{valSite.site}</option>
                                                                        {valSite.device.map((db) =>
                                                                            <option key={ db._id } value={ db.name }>{db.name}</option>
                                                                        )}
                                                                    </>
                                                                )}
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <select
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                            id="userType"
                                                            name="userType"
                                                            value={ dvc.gauge }
                                                            onChange={ (e) => selectGauge(e.target.value, i, ind) }
                                                        >
                                                            <option value="" disabled selected>---</option>
                                                            {gauge.map((val, indx) =>
                                                                <option key={ indx } value={ val.name }>{val.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <select
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                            id="userType"
                                                            name="userType"
                                                            value={ dvc.order_key }
                                                            onChange={ (e) => selectType(e.target.value, i, ind) }
                                                        >
                                                            <option value="" disabled selected>---</option>
                                                            <option value="asc">asc</option>
                                                            <option value="desc">desc</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <select
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                            id="group"
                                                            name="group"
                                                            value={ dvc.group_as }
                                                            onChange={ (e) => selectGroup(e.target.value, i, ind) }
                                                        >
                                                            <option value="" disabled selected>---</option>
                                                            {jsonData &&  jsonData.group.map((val, index) =>
                                                                <option key={ index } value={ val.esVal }>{val.value}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <select
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                            id="userType"
                                                            name="userType"
                                                            value={ dvc.mode }
                                                            onChange={ (e) => selectMode(e.target.value, i, ind) }
                                                        >
                                                            <option value="" disabled selected>---</option>
                                                            {jsonData &&  jsonData.mode.map((val, index) =>
                                                                <option key={ index } value={ val.value }>{val.value}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <select
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                            id="userType"
                                                            name="userType"
                                                            value={ dvc.show_latest == true ? 'true' : 'false' }
                                                            onChange={ (e) => selectLatest(e.target.value, i, ind) }
                                                        >
                                                            <option value="" selected>---</option>
                                                            <option value="true">true</option>
                                                            <option value="false">false</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <select
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                            id="userType"
                                                            name="userType"
                                                            value={ dvc.show_daily_hours == true ? 'true' : 'false' }
                                                            onChange={ (e) => selectDailyHours(e.target.value, i, ind) }
                                                        >
                                                            <option value="" selected>---</option>
                                                            <option value="true">true</option>
                                                            <option value="false">false</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                                <div className='w-full relative mt-2'>
                                                    <div className='relative group mt-1'>
                                                        <input type="color" defaultValue={ dvc.line_color } value={ dvc.line_color } onChange={ (e) => selectColor(e.target.value, i, ind) }/>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="xl:px-6 px-3 py-4 whitespace-nowrap text-right text-md font-semibold">
                                                <button type='button' onClick={ () => deleteDeviceData(i, ind) } className="text-red-500 cursor-pointer">{t('Delete')}</button>
                                            </td>
                                        </tr>
                                    </tbody> )}

                            </table>
                        </div>
                        <button type='button' onClick={ () => handleOnclickDevice(i) } className='py-[7px] w-[130px] font-semibold rounded-lg bg-[#599bfb] text-white mt-[20px] px-[10px] flex justify-center' disabled={ !deleteDevice && i === id && deviceOBJ.device === '---' && deviceOBJ.gauge === '---' }>
                            {t('Add_Device')}
                        </button>
                        <button type='button' onClick={ () =>  handleOnclickNotification(i) } className='py-[8px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white mt-[20px] px-[10px] flex justify-center' disabled={ !deleteDevice && i === id && deviceOBJ.device === '---' && deviceOBJ.gauge === '---' }>
                            {t('Add_Notification')}
                        </button>
                        {val.notificationDisplay  &&
                        <><div className='mt-7'>
                            <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Notification_Condition')}</label>
                            <input type="text" value={ val.notification_condition } className='my-[6px] appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm'
                                onChange={ (e) => handleOnChangeNotification(e.target.value, i) } />
                        </div><div className='mt-3'>
                            <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Notification_Subject')}</label>
                            <input type="text" value={ val.notification_subject } className='my-[6px] appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm'
                                onChange={ (e) => handleOnChange(e.target.value, i, 'notification_subject') } />
                        </div><div className='mt-3'>
                            <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Notification_Message')}</label>
                            <input type="text" value={ val.notification_message } className='my-[6px] appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm'
                                onChange={ (e) => handleOnChange(e.target.value, i, 'notification_message') } />
                        </div><div className='mt-3'>
                            <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Notification_Delay')}</label>
                            <input type="number" value={ val.notification_delay } className='my-[6px] appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm'
                                onChange={ (e) => handleOnChange(e.target.value, i, 'notification_delay') } />
                        </div><div className="w-[50%] flex gap-4 mt-3">
                            <div className='w-full'>
                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Profile')}</label>
                                <div className="mt-1">
                                    <select id="name" name="name" type="name"
                                        onChange={ (e) => handleOnChange(e.target.value, i, 'profile') }
                                        value={ val.profile }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm">
                                        {profile.map((val) => <option key={ val._id } value={ val._id }>{val.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div></>
                        }
                        <div>
                            <div className="">
                                <div className="w-full sm:flex gap-4 mt-3">
                                    <div className='sm:w-[70%]'>
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Evaluation')}</label>
                                        <div className="mt-1">
                                            <input id="name" value={ val.evaluation } onChange={ (e) => handleOnchangeEvalution(e.target.value, i) } name="name" type="name" autoComplete="name" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"/>
                                        </div>
                                    </div>
                                    <div className='sm:w-[30%] sm:mt-0 mt-3'>
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Unit')}</label>
                                        <div className="mt-1">
                                            <input id="name" value={ val.unit } onChange={ (e) => handleOnchangeUnit(e.target.value, i) } name="name" type="name" autoComplete="name" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex gap-4 mt-2">
                                    <div className='py-3 w-full'>
                                        <div className='sm:w-[30%]'>
                                            <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('Display_Type')}</label>
                                            <select id="name" name="name" type="name"
                                                value={ val.type }
                                                onChange={ (e) => handleOnchangeType(e.target.value, i) } className="mt-3 appearance-none block w-full px-5 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm">
                                                <option value="Numeric">Numeric</option>
                                                <option value="Bool">Bool</option>
                                                <option value="Count">Count</option>
                                            </select>
                                        </div>
                                        {
                                            val.type === 'Bool' &&
                                            <div className="mt-3 flex 2xl:flex-nowrap flex-wrap gap-7">
                                                <div className='sm:flex items-center gap-4 '>
                                                    <div className="flex flex-col gap-2 mt-2">
                                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad] w-max">{t('true_text')}</label>
                                                        <input
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" type="text" value={ val.trueText }
                                                            onChange={ (e) => handleOnChange(e.target.value, i, 'trueText') } />
                                                    </div>
                                                    <div className="flex flex-col gap-2 mt-2">
                                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad] w-max">{t('false_text')}</label>
                                                        <input
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" type="text" value={ val.falseText }
                                                            onChange={ (e) => handleOnChange(e.target.value, i, 'falseText') } />
                                                    </div>
                                                </div>
                                                <div className='grid lg:grid-cols-4 sm:grid-cols-2 2xl:gap-6 gap-4 w-full'>
                                                    <div className="flex flex-col gap-2 mt-2">
                                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad] w-max">{t('true_color')}</label>
                                                        <input type="color" value={ val.trueColor } onChange={ (e) => handleOnChange(e.target.value, i, 'trueColor') }/>
                                                    </div>
                                                    <div className="flex flex-col gap-2 mt-2">
                                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad] w-max">{t('false_color')}</label>
                                                        <input type="color" value={ val.falseColor } onChange={ (e) => handleOnChange(e.target.value, i, 'falseColor') }/>
                                                    </div>
                                                    <div className="flex flex-col gap-2 mt-2">
                                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad] w-max">{t('true_text_color')}</label>
                                                        <input type="color" value={ val.textTrueColor } onChange={ (e) => handleOnChange(e.target.value, i, 'textTrueColor') }/>
                                                    </div>
                                                    <div className="flex flex-col gap-2 mt-2">
                                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad] w-max">{t('false_text_color')}</label>
                                                        <input type="color" value={ val.textFalseColor } onChange={ (e) => handleOnChange(e.target.value, i, 'textFalseColor') }/>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='grid xl:grid-cols-3 md:grid-cols-2 my-4 gap-4'>
                                    <div className="grid md:grid-cols-2 gap-4 items-start w-max">
                                        <div className="flex items-center gap-3">
                                            <input
                                                id={ `comments-${ 1 }` }
                                                type="checkbox"
                                                value={ val.isDiff }
                                                checked={ val.isDiff }
                                                onChange={ () => handleOnchangeDiff(val.isDiff, i) }
                                                className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                            />
                                            <div className='flex gap-2 items-center'>
                                                <label htmlFor={ `comments-${ 1 }` } className="select-none font-medium text-[#5a7bad]">
                                                    {t('Max_diff')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <input
                                                id={ `comments-${ 1 }` }
                                                type="checkbox"
                                                value={ val.accumulative }
                                                checked={ val.accumulative }
                                                onChange={ () => handleOnchangeAccumulative(val.accumulative, i) }
                                                className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                            />
                                            <div className='flex gap-2 items-center'>
                                                <label htmlFor={ `comments-${ 1 }` } className="select-none font-medium text-[#5a7bad]">
                                                    {t('accumulative')}
                                                </label>
                                            </div>
                                        </div>
                                        {userType !== 'user' && <div className="flex items-center gap-3">
                                            <input
                                                id={ `comments-${ 2 }` }
                                                type="checkbox"
                                                value={ val.removeZeroValues }
                                                checked={ val.removeZeroValues }
                                                onChange={ () => handleOnchangeToZeroValues(val.removeZeroValues, i) }
                                                className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                            />
                                            <div className='flex gap-1 items-center'>
                                                <label htmlFor={ `comments-${ 2 }` } className="select-none font-medium text-[#5a7bad]">
                                                    {t('Remove_value')}
                                                </label>
                                            </div>
                                        </div>}
                                    </div>
                                </div>

                                <div className="w-full flex">
                                    <div className="flex w-[30%] flex-col gap-2 mt-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">{t('default_line_color')}</label>
                                        <input type="color" value={ val.default_line_color } onChange={ (e) => handleOnchangeDefault_line_color(e.target.value, i) }/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type='button' onClick={ () => deleteGroup(i) } className="text-red-500 cursor-pointer">{t('Delete')}</button>
            </div>)}
        </>
    )
}

export default GroupList