import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Chinese } from './Language/ch/common'
import { EngWord } from './Language/en/common'

const resources = {
    en: {
        common: EngWord,
    },
    ch:{
        common:Chinese
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)

    .init({
        detection: {
            order: [ 'querystring', 'cookie' ],
            lookupCookie: 'language',
            lookupQuerystring: 'lng',
            cookieMinutes: 10 ** 10
        },
        defaultNS: 'common',
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    })

export const appDirection = i18n.dir(i18n.language)
export const appLocale = i18n.language

export default i18n
