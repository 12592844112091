import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API } from '../../api'
import { localStorageGet, localStorageSet } from '../../utils/localstorage'

const Notification = () => {
    const navigate = useNavigate()
    const [ loader, setLoader ] = useState(false)
    const [ data, setData ] = useState([]);
    const [ open, setOpen ] = useState(false)
    const [ openProfile, setOpenProfile ] = useState(false)
    const [ notifyData, setNotification ] = useState('')
    const [ userPermisiions, setUserPermisiions ] = useState([])
    const [ showCompany, setShowCompany ] = useState([])
    const[ companyList, setCompanyList ] = useState([])
    const [ company, setCompany ]=useState({})
    const cancelButtonRef = useRef(null)
    const { t } = useTranslation()

    const userType = localStorageGet('type')

    const getNotifications = async () => {
        setLoader(true)
        const response = await API('/api/lists/notification')
        const filterValue = response.notification.filter((val) => val.isDelete === false)
        setData(filterValue)
        setShowCompany(filterValue.map((val) => val.company_name))
        if(userType !== 'super-admin') {
            const permissions = localStorageGet('permissions')
            setUserPermisiions(JSON.parse(permissions))
        }
        setLoader(false)
    }

    const deleteNotification = async () => {
        const data = {
            method: 'PATCH',
            data:{
                company_id: notifyData.company_id,
                company_name:notifyData.company_name,
                email:notifyData.email,
                name:notifyData.name,
                sms:notifyData.sms,
                whatsapp:notifyData.whatsapp,
                isDelete:true
            }
        }
        const response = await API(`/api/update/notification/${ notifyData._id }`, data)

        if(response.success){
            setOpen(false)
            getNotifications()
            toast.error(`Notification ${ notifyData.name } deleted.`, { autoClose: 5000 })
        }
    }

    const getSiteData = async () => {
        const response = await API('/api/site/lists')
        if (userType == 'super-admin') {
            setCompanyList(response.sites.map((val) => { return { name: val.name, _id : val._id  } }))
        }
        else{
            const sitesData = JSON.parse(localStorageGet('sites'))
            const displayList = response.sites.filter((val) => sitesData.includes(val._id))
            setCompanyList(displayList.map((val) => { return { name: val.name, _id : val._id  } }))

            const userId = localStorageGet('user_id')
            const loginUser = await API(`/api/list/member/${ JSON.parse(userId) }`)
            localStorageSet('permissions', JSON.stringify(loginUser.member.permissions))
        }
    }

    const handleSubmit = () =>{
        setOpenProfile(false)
        navigate(`/addprofile?${ company }`)
    }

    useEffect(() => {
        getNotifications()
        getSiteData()
    }, [])

    const array = showCompany.filter((item, index) => showCompany.indexOf(item) === index)

    return (
        <>
            <div>
                <div className='flex gap-2 items-center'>
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {t('Notifications')}
                    </h3>
                    <Link className='ml-6 inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]' onClick={ ()=> setOpenProfile(true) }>{t('New_Profile')}</Link>
                    <Transition.Root show={ openProfile } as={ Fragment }>
                        <Dialog as="div" className="relative z-10" initialFocus={ cancelButtonRef } onClose={ setOpenProfile }>
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={ Fragment }
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                <div>
                                                    <div>
                                                        <label htmlFor="" className='font-[500]'>{t('Company_Name')}</label>
                                                    </div>
                                                    <select name="" className='w-[100%] h-[40px] outline-none mt-[5px] focus:shedow-none' id="" onChange={ (e) => setCompany(e.target.value) }>
                                                        {companyList.map((val) => {
                                                            return <option key={ val._id } value={ `name=${ val.name }&_id=${ val._id }` }>{val.name}</option>
                                                        })}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md bg-[#599bfb] px-5 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                                                    onClick={ () => handleSubmit()  }
                                                >
                                                    {t('Save')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-5 py-2 text-sm font-semibold text-gray-900  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    onClick={ () => setOpenProfile(false) }
                                                    ref={ cancelButtonRef }
                                                >
                                                    {t('Cancel')}
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>
                {array.map((values) => values &&
                <div className='mt-6 overflow-x-auto' key={ values._id }>
                    <p className='font-bold mb-2'>{values}</p>
                    <table key={ values._id } className='w-full divide-gray-200 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-[15px]'>
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider min-w-[300px]">
                                    {t('Name')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider min-w-[300px]">
                                    {t('Company_Name')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider min-w-[300px]">
                                    {t('Email')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider min-w-[300px]">
                                    {t('SMS')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider min-w-[300px]">
                                    {t('Whatsapp')}
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                    <span className="sr-only">{t('Action')}</span>
                                </th>
                            </tr>
                        </thead>
                        {(!loader && userType == 'super-admin') ?
                            data.map((val) => val.company_name === values ? <tbody key={ val._id } className="bg-white divide-y divide-gray-200">
                                <tr key={ val._id }>
                                    <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.name}
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.company_name ? val.company_name : '---'}
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.email.map((val) => val).length !== 0 ? val.email.map((val) => val.value).join(',') : '---'}
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.sms.map((val) => val).length !== 0 ? val.sms.map((val) => val.value).join(',') : '---'}
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.whatsapp.map((val) => val).length !== 0 ? val.whatsapp.map((val) => val.value).join(',') : '---'}
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-right text-md font-semibold">
                                        {userType == 'super-admin' ? <Link to={ `/editprofile/:id?${ val._id }` } className="text-primary hover:text-primary-focus mr-3">{t('Edit')}</Link>
                                            : userPermisiions.includes('Edit Notifications') &&
                                                <Link to={ `/editprofile/:id?${ val._id }` } className="text-primary hover:text-primary-focus mr-3">{t('Edit')}</Link>
                                        }
                                        {userType == 'super-admin' ?
                                            <div className="inline" onClick={ ()=>{ setOpen(true); setNotification(val)} }>
                                                <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                                            </div>
                                            : userPermisiions.includes('Delete Notifications') &&
                                                <div className="inline" onClick={ ()=>{ setOpen(true); setNotification(val)} }>
                                                    <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                                                </div>}
                                    </td>
                                </tr>
                            </tbody>: '')

                            : (!loader && userPermisiions.includes('View Notifications') )?
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {data.length > 0 && data.map((val) =>
                                        <tr key={ val._id }>
                                            <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                                {val.name}
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                                {val.company_name ? val.company_name : '---'}
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                                {val.email.map((val) => val).length !== 0 ? val.email.map((val) => val.value).join(',') : '---'}
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                                {val.sms.map((val) => val).length !== 0 ? val.sms.map((val) => val.value).join(',') : '---'}
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-md font-semibold text-gray-500">
                                                {val.whatsapp.map((val) => val).length !== 0 ? val.whatsapp.map((val) => val.value).join(',') : '---'}
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-right text-md font-semibold">
                                                {userType === 'super-admin' ? <Link to={ `/editprofile/:id?${ val._id }` } className="text-primary hover:text-primary-focus mr-3">Edit</Link>
                                                    : userPermisiions.includes('Edit Notifications') &&
                                                        <Link to={ `/editprofile/:id?${ val._id }` } className="text-primary hover:text-primary-focus mr-3">{t('Edit')}</Link>
                                                }
                                                {userType === 'super-admin' ?
                                                    <div className="inline" onClick={ ()=>{ setOpen(true); setNotification(val)} }>
                                                        <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                                                    </div>
                                                    : userPermisiions.includes('Delete Notifications') &&
                                                        <div className="inline" onClick={ ()=>{ setOpen(true); setNotification(val)} }>
                                                            <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                                                        </div> }
                                            </td>
                                        </tr>)}
                                </tbody> : 'Access denied'}
                    </table>
                </div>
                )}
            </div>
            <Transition.Root show={ open } as={ Fragment }>
                <Dialog as="div" className="relative z-10" initialFocus={ cancelButtonRef } onClose={ setOpen }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    {t('Delete')} {notifyData.name}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {t('Delete_Notificaiton_Description')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex justify-end sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[transparent] focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={ () => setOpen(false) }
                                            ref={ cancelButtonRef }
                                        >
                                            {t('Cancel')}
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={ deleteNotification }
                                        >
                                            {t('Delete')}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default Notification