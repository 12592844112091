import {
    lightningChart,
    Themes,
    AxisTickStrategies,
    SolidFill,
    ColorHEX,
    SolidLine,
    UIDraggingModes,
    PointShape
} from '@arction/lcjs';
import React, { useRef, useEffect } from 'react';
import moment from 'moment';

const ChartLine = (props) => {
    const { data, id, val } = props;
    // console.log(data)
    const chartRef = useRef(undefined);

    useEffect(() => {
        if (data?.length === 0) return;
        // Create chart, series and any other static components.
        // NOTE: console log is used to make sure that chart is only created once, even if data is changed!
        console.log('create chart');
        // const chart = lightningChart().ChartXY({ container: id })
        const chart = lightningChart()
            .ChartXY({
                theme: Themes.light,
                container: id
            })
            .setBackgroundFillStyle(new SolidFill({ color: ColorHEX('#ffffffff') }))
            .setAnimationsEnabled(false)
            .setTitle('')
            // .setAutoCursor((cursor) => cursor.setResultTableAutoTextStyle(true).setTickMarkerXAutoTextStyle(true).setTickMarkerYAutoTextStyle(true))

        const axisX = chart
            .getDefaultAxisX()

            .setChartInteractions(false)
            .setTickStrategy(AxisTickStrategies.DateTime, (ticks) => ticks
                .setMinorTickStyle((style) => style
                    .setLabelFont((font) => font.setSize(12))
                )
                .setMajorTickStyle((style) => style
                    .setLabelFont((font) => font.setSize(12))
                )
                .setGreatTickStyle((style) => style
                    .setLabelFont((font) => font.setSize(8))
                )
            )

        data?.data?.map((e, i) => {
            const yAxis = chart.getDefaultAxisY()
                .setChartInteractions(false)
                .setTickStrategy(AxisTickStrategies.Numeric, (ticks) => ticks
                    .setMinorTickStyle((style) => style
                        .setLabelFont((font) => font.setSize(10))
                    )
                    .setMajorTickStyle((style) => style
                        .setLabelFont((font) => font.setSize(10))
                    )
                )
            // .setInterval({
            //     start: data?.minValue ? data?.minValue : undefined,
            //     end: data?.maxValue ? data?.maxValue : undefined
            // })
            if ((data.data[ i ]?.default_line_color !== '' && data.data[ i ]?.default_line_color !== undefined) || (data.data[ i ]?.line_color !== '' && data.data[ i ]?.line_color !== undefined ) ) {

                const splineSeries1 = chart
                    .addPointLineSeries({
                        xAxis: axisX,
                        yAxis: yAxis
                    })
                    .setPointSize(3)
                    .setStrokeStyle(new SolidLine({
                        thickness: 2,
                        fillStyle: new SolidFill({ color: ColorHEX( data.data[ i ].default_line_color !== '' ? data.data[ i ].default_line_color : data.data[ i ].line_color ) })
                    }))
                    .setPointFillStyle(new SolidFill({
                        color: ColorHEX( data.data[ i ].default_line_color !== '' ? data.data[ i ].default_line_color : data.data[ i ].line_color )
                    }))
                    .setName(data?.series?.[ i ]?.yName)

                const arr = [];
                try {
                    for (const bucket of data.data[ i ].buckets) {
                        if(val.groups[ 0 ].devices[ 0 ].group_as == '1d' || val.groups[ 0 ].devices[ 0 ].group_as == '7d' || val.groups[ 0 ].devices[ 0 ].group_as == '30d'){
                            arr.push({ x: moment.utc((''+bucket.x).replace('T', ' ').replace('Z', ' ')).local().startOf('day'), y: bucket.y });
                        }
                        else{
                            arr.push({ x: moment.utc((''+bucket.x).replace('T', ' ').replace('Z', ' ')).local(), y: bucket.y });
                        }
                    }
                } catch (err) {
                    arr.push({ x: 0, y: 0 });
                    return;
                }
                splineSeries1.add(arr);
            }
            else{
                const splineSeries1 = chart
                    .addPointLineSeries({
                        xAxis: axisX,
                        yAxis: yAxis
                    })
                    .setPointSize(3)
                    .setName(data?.series?.[ i ]?.yName)

                const arr = [];
                try {
                    for (const bucket of data.data[ i ].buckets) {
                        // arr.push({ x: moment.utc((''+bucket.x).replace('T', ' ').replace('Z', ' ')).local(), y: bucket.y });
                        if(val.groups[ 0 ].devices[ 0 ].group_as == '1d' || val.groups[ 0 ].devices[ 0 ].group_as == '7d' || val.groups[ 0 ].devices[ 0 ].group_as == '30d'){
                            arr.push({ x: moment.utc((''+bucket.x).replace('T', ' ').replace('Z', ' ')).local().startOf('day'), y: bucket.y });
                        }
                        else{
                            arr.push({ x: moment.utc((''+bucket.x).replace('T', ' ').replace('Z', ' ')).local(), y: bucket.y });
                        }
                    }
                } catch (err) {
                    arr.push({ x: 0, y: 0 });
                    return;
                }
                splineSeries1.add(arr);
            }
        });

        chart
            .addLegendBox()
            .setDraggingMode(UIDraggingModes.draggable)
            .setTitle('')
            .add(chart)
        // Dispose example UI elements automatically if they take too much space. This is to avoid bad UI on mobile / etc. devices.
            .setAutoDispose({
                type: 'max-width',
                maxWidth: 0.3
            });

        chartRef.current = { chart };

        // Return function that will destroy the chart when component is unmounted.
        return () => {
            // Destroy chart.
            chart.dispose();
            chartRef.current = undefined;
        };
    }, [ id, data ]);

    return <div id={ id } className="chart border h-[35vh]"></div>;
};

export default ChartLine;
