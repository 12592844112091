import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { API } from '../../api'
import { localStorageGet } from '../../utils/localstorage'
import { set } from 'lodash'

const Dashboard = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [ sites, setSites ] = useState([])
    const [ filteredSites, setFilteredSites ] = useState([])
    const [ category, setCategory ] = useState([])
    const [ filteredCategory, setFilteredCategory ] = useState([])
    const [ searchCategory, setSearchCategory ] = useState([])
    const [ loader, setloading ] = useState(false)

    const siteId = JSON.parse(localStorageGet('sites'))
    const type = localStorageGet('type')

    function compareStrings(a, b) {
        // Assuming you want case-insensitive comparison
        a = a.toLowerCase();
        b = b.toLowerCase();

        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }

    const filter = async(cat) =>{
        try {
            const r = []
            for(const i of sites){
                if(i.category === cat){
                    r.push(i)
                }
            }
            r.sort(function(a, b) {
                return compareStrings(a.name, b.name);
            })
            setFilteredSites(r)
        } catch(error){
            toast.error(error, { autoClose: 5000 })
        }finally {
            setloading(false)
        }
    }
    const getSiteData = async() =>{
        try{
            setloading(true)
            const response = await API('/api/site/lists')
            // console.log(response.sites)
            if(type !== 'super-admin'){
                const filterData = response.sites.filter((val) => siteId.includes(val._id))
                console.log(filterData)
                setSites(filterData)
                const cats = new Set()
                for(const i of filterData){
                    // console.log(i.category)
                    if(i.category){
                        cats.add(i.category)
                    }
                }
                // console.log(cats)
                setCategory([...cats])
                setFilteredCategory([...cats])
            }else{
                setSites(response.sites)
                const cats = new Set()
                for(const i of response.sites){
                    if(i?.category){
                        cats.add(i.category)
                    }
                }
                setCategory([...cats])
                setFilteredCategory([...cats])
            }
        }catch(error){
            toast.error(error, { autoClose: 5000 })
        }finally{
            setloading(false)
        }
    }

    useEffect(() => {
        getSiteData()
    }, [])

    useEffect(() => {
        console.log(searchCategory)
        if(searchCategory === ''){
            setFilteredCategory(category)
            setFilteredSites([])
        }
        else{
            const filtered = category.filter((val) => {
                // console.log(val +' '+ searchCategory+ ' | '+ val.toLowerCase().includes(searchCategory.toLowerCase()))
                return val.toLowerCase().includes(searchCategory.toLowerCase())
            })
            setFilteredCategory(filtered)
            setFilteredSites([])
        }
    }, [searchCategory])

    return (
        <>
            <div>
                <div className='flex justify-between'>
                    <div className='flex flex-col gap-5 w-full'>
                        <h3 className="text-xl leading-6 font-bold text-gray-500">
                            {t('Dashboard')}
                        </h3>

                        <h4 className="text-xl leading-6 font-medium text-gray-500">
                            {t('Category')}
                        </h4>
                        <input placeholder='Search Category' value={ searchCategory }
                            onChange={ (e) => setSearchCategory(e.target.value) } className=' rounded-md bg-white py-1.5 px-4 max-w-[240px] w-full text-left shadow-sm ring-1 ring-inset ring-gray-300 text-xl sm:leading-6 text-[#5a7bad]' />
                        {loader === false ?
                            <div className='grid grid-cols-5 align-center'>
                                {filteredCategory.sort().map((val, i) =>
                                    <button  key={ i } onMouseOver={ () => filter(val) } onClick={ () => filter(val) } className=" items-center px-4 py-2 mr-4 ml-4 border border-transparent font-semibold rounded-md shadow-sm text-white bg-[#599bfb] focus:ring-2 focus:ring-offset-2 focus:ring-primary mt-2 mb-2">
                                        {val}
                                    </button>
                                )}
                            </div>:null}

                        {filteredSites.length > 0 && <h4 className="text-xl leading-6 font-medium text-gray-500">
                            {t('Sites')}
                        </h4>}
                        {loader === false ?
                            <div className='grid grid-cols-5 align-center'>
                                {filteredSites.sort().map((val, i) =>
                                    <button  key={ i } onClick={ () => navigate(`/site/${ val._id }`) } className=" items-center px-4 py-2 mr-4 ml-4 border border-transparent font-semibold rounded-md shadow-sm text-white bg-[#599bfb] focus:ring-2 focus:ring-offset-2 focus:ring-primary mt-2 mb-2">
                                        {val.name}
                                    </button>
                                )}
                            </div> : null}
                    </div>
                    <div className='justify-end flex flex-col items-end'>
                        <h3 className="text-lg leading-6 font-normal text-gray-500">
                            {/* <span id="date-time">2023-02-22 18:08</span> */}
                        </h3>
                        <div className='flex gap-2 items-center'>
                            {/* <div className="h-[12px] w-[12px] rounded-full bg-[#599bfb]"></div> */}
                            {/* <h4 className='text-gray-500'>Update In 38s</h4> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard