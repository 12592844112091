/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { toast } from 'react-toastify'
import { API } from '../../../api'
import { useLocation, useNavigate } from 'react-router'
import { localStorageGet } from '../../../../src/utils/localstorage'
import Permissions from '../../Json/permissions.json'
import { useTranslation } from 'react-i18next'

const profileValidationSchema = yup.object({
    email: yup.string('Enter Your Email')
        .required('Email is required')
        .test('is-email', 'Invalid email', (value) => {
            if (value) {
                return value.includes('@') ? yup.string().email().isValidSync(value) : true
            }
            return true
        }),
    password: yup
        .string('Enter your password')
        .min(6, 'Password must be at least 6')
        .required('Password is required'),
    confirmPassword: yup
        .string('Enter your confirm password')
        .min(6, 'Password must be at least 6')
        .oneOf([ yup.ref('password'), null ], 'Passwords must match')
        .required('Confirm Password is required'),
    userType: yup
        .string('Select userType')
        .required('Usertype is required'),
    company: yup
        .string('Enter Your Company name')
        .required('Company name is required'),
})

const EditMember = () => {
    const [ siteData, setSiteData ] = useState([])
    const [ siteSubmit, setSiteSubmit ] = useState([])
    const [ permissionSubmit, setPermissionSubmit ] = useState([])
    const [ loading, setloading ] = useState(null)
    const [ form, setForm ] = useState('')
    const userType = localStorageGet('type')
    const { t } = useTranslation()

    const routes = useLocation()
    const navigate = useNavigate()

    const addSites = (e) => {
        setSiteSubmit((prev) => {
            if(prev.includes(e.target.value)){
                return prev.filter((val) => val !== e.target.value)
            }else {
                return [ ...prev, e.target.value ]
            }
        })
    }

    const addPermissions = (e) => {
        if(e.target.value === 'all'){
            const arr = []
            Permissions.map((val) => arr.push(val.name))
            setPermissionSubmit((prev) => {
                if(prev.includes(e.target.value)){
                    return []
                }else {
                    return [ ...arr, 'all' ]
                }
            })
        } else {
            setPermissionSubmit((prev) => {
                if(prev.includes(e.target.value)){
                    return prev.filter((val) => val !== e.target.value)
                }else {
                    return [ ...prev, e.target.value ]
                }
            })
        }
    }

    const getFormData = async () => {
        try {
            setloading(true)
            const ID = location.search.replace('?id:', '')
            if(ID){
                const response = await API(`/api/list/member/${ ID }`)
                setForm(response.member)
                setSiteSubmit(response.member.sites)
                setPermissionSubmit(response.member.permissions)
            }else{
                setForm('')
            }
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        } finally{
            setloading(false)
        }
    }

    const getSiteData = async () => {
        const response = await API('/api/site/lists')
        setSiteData(response.sites)
    }

    useEffect(() => {
        getFormData()
        getSiteData()
    }, [])

    return (
        <div>
            <div className="">
                <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                    {routes.pathname === '/profile' ? t('Profile') : t('Create_Member')}
                </h3>
                {!loading ?
                    <Formik
                        initialValues={ {
                            email: form.email,
                            password: form.password,
                            confirmPassword: form.confirm_password,
                            userType: form.userType,
                            company: form.company,
                            site: form.sites,
                            permissions: form.permissions
                        } }
                        validationSchema={ profileValidationSchema }
                        onSubmit={ async (values) => {
                            try {
                                const data = {
                                    method: 'PATCH',
                                    data: {
                                        confirm_password: values.confirmPassword,
                                        company: values.company,
                                        email: values.email,
                                        password: values.password,
                                        userType: values.userType,
                                        sites: siteSubmit,
                                        permissions: permissionSubmit
                                    }
                                }
                                const response = await API(`/api/update/member/${ form._id }`, data)
                                const message = response.data?.message || 'Something went to wrong'

                                if (response.success) {
                                    toast.success(response.message, { autoClose: 5000 })
                                    navigate('/membermanage')
                                } else {
                                    if (response?.data?.message?.errors && typeof response?.data?.message?.errors === 'object') {
                                        const obj = Object.keys(response.data.message.errors)
                                        for(const m of obj){
                                            toast.error(response.data.message.errors[ m ], { autoClose: 5000 })
                                        }
                                    }
                                    else{
                                        toast.error(message.errors, { autoClose: 5000 })
                                    }
                                }

                            } catch (error) {
                                toast.error('Internal Server Error', { autoClose: 5000 })
                            }
                        } }
                    >
                        {({ values,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={ handleSubmit }>
                                <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                                    <div>
                                        <h2 className="text-xl leading-6 font-bold text-[#599bfb]">{t('Personal_Details')}</h2>
                                        <p className="mt-1 text-sm font-medium text-[gray]"> {t('Manage_Information')} </p>
                                    </div>
                                    <div className='flex gap-[20px] sm:flex-row flex-col'>
                                        <div className='w-full'>
                                            <label htmlFor="email" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Email_address')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="email" name="email" placeholder='faisal.mehmood@energy-vergence.com' value={ values.email } onChange={ handleChange } type="email" autoComplete="email" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="email" /></div>
                                            </div>
                                        </div>
                                        <div className='w-full relative'>
                                            <label htmlFor="Company" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Company')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="company" name='company' onChange={ handleChange } value={ values.company } placeholder='' type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="company" /></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex gap-[20px] sm:flex-row flex-col'>
                                        <div className='w-full relative'>
                                            <label htmlFor="email" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('User_Type')}
                                            </label>
                                            <select
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                id="userType"
                                                name="userType"
                                                value={ values.userType }
                                                onChange={ handleChange }
                                            >
                                                <option value="" disabled>Select User Type...</option>
                                                {(userType == 'super-admin' || userType == 'admin') && <option value="user">User</option>}
                                                {(userType == 'super-admin' || userType == 'admin') && <option value="admin">Admin</option>}
                                                {userType == 'super-admin' && <option value="super-admin">Super admin</option>}
                                            </select>
                                            <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="userType" /></div>
                                        </div>
                                        <div className='w-full relative'></div>
                                    </div>

                                    <div className='w-full'>
                                        <label htmlFor="site" className="block text-sm font-medium text-[#5a7bad]">
                                            {t('Sites')}
                                        </label>
                                        <div className="mt-1">
                                            <div className="grid grid-cols-3 gap-2">
                                                {siteData?.map((val, i) => <>
                                                    <div className="relative flex items-start" key={ i }>
                                                        <div className="flex items-center gap-3">
                                                            <input
                                                                id={ `comments-${ i }` }
                                                                type="checkbox"
                                                                name="site"
                                                                value={ val._id }
                                                                checked={ siteSubmit.includes(val._id) }
                                                                onChange={ (e) => addSites(e) }
                                                                className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                            />
                                                            <div className='flex gap-2 items-center'>
                                                                <label htmlFor={ `comments-${ i }` } className="select-none  font-medium text-[#5a7bad]">
                                                                    {val.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className="text-sm leading-6 font-medium text-[#5a7bad] ">
                                            {t('Permissions')}
                                        </h3>
                                        <div className="relative flex items-start">
                                            <div className="flex items-center gap-3">
                                                <input
                                                    type="checkbox"
                                                    value='all'
                                                    onChange={ (e) => addPermissions(e) }
                                                    checked={ permissionSubmit.includes('all') }
                                                    className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                />
                                                <div className='flex gap-2 items-center'>
                                                    <label htmlFor={ `comments-${ 1 }` } className="w-max whitespace-nowrap font-medium h-[21px] select-none flex items-center rounded-md bg-[#5a7bad] px-[10px] text-white text-xs">all</label>
                                                    <label htmlFor={ `comments-${ 1 }` } className="select-none  font-medium text-[#5a7bad]">
                                                        {t('All_permissions')}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='grid xl:grid-cols-3 md:grid-cols-2 mt-2 gap-4'>
                                            {Permissions.map((val, i) => {
                                                return (
                                                    <div className="relative flex items-start" key={ i }>
                                                        <div className="flex items-center gap-3">
                                                            <input
                                                                id={ i }
                                                                type="checkbox"
                                                                name="permission"
                                                                value={ val.name }
                                                                checked={ permissionSubmit.includes(val.name) }
                                                                onChange={ (e) => addPermissions(e) }
                                                                className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                            />
                                                            <div className='flex gap-2 items-center'>
                                                                <label htmlFor={ `comments-${ i }` } className="w-max whitespace-nowrap font-medium h-[21px] select-none flex items-center rounded-md bg-[#5a7bad] px-[10px] text-white text-xs">{val.badge}</label>
                                                                <label htmlFor={ `comments-${ i }` } className="select-none  font-medium text-[#5a7bad]">
                                                                    {val.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className='flex gap-[20px] sm:flex-row flex-col'>
                                        <div className='w-full'>
                                            <label htmlFor="Password" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Password')}
                                            </label>
                                            <div className="mt-1">
                                                <input type="password" required="" id='password' name="password" value={ values.password } onChange={ handleChange } className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="password" /></div>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <label htmlFor="Password" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Confirm_Password')}
                                            </label>
                                            <div className="mt-1">
                                                <input type="password" required="" id='confirmPassword' name='confirmPassword' value={ values.confirmPassword } onChange={ handleChange } className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="confirmPassword" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='button' onClick={ () => navigate('/membermanage') } className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>
                                        {t('Cancel')}
                                    </button>
                                    <button type='submit' className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>
                                        {t('Save')}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik> :null }
            </div>
        </div>
    )
}

export default EditMember