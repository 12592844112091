import cn from 'classnames';
import React from 'react';
const Button = ({
    as,
    variant = 'primary',
    className = '',
    children,
    ...props
}) => {
    const Component = as || 'button';

    return (
        <Component
            className={ cn(
                'rounded-md px-3 py-2 hover:bg-opacity-90 flex gap-3 items-center',
                {
                    'bg-primary text-white': variant === 'primary',
                    'border border-gray-300 shadow-md': variant === 'secondary',
                },
                className
            ) }
            { ...props }
        >
            {children}
        </Component>
    );
};

export default Button;
