import React from 'react'

export const Logo = () => {
    return (
        <svg style={ { stroke: '#599bfb' } } className="h-10 w-10 stroke-current text-primary fill-primary" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M10 42C13.3137 42 16 39.3137 16 36C16 32.6863 13.3137 30 10 30C6.68629 30 4 32.6863 4 36C4 39.3137 6.68629 42 10 42Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M38 44C41.3137 44 44 41.3137 44 38C44 34.6863 41.3137 32 38 32C34.6863 32 32 34.6863 32 38C32 41.3137 34.6863 44 38 44Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M22 28C26.4183 28 30 24.4183 30 20C30 15.5817 26.4183 12 22 12C17.5817 12 14 15.5817 14 20C14 24.4183 17.5817 28 22 28Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M34 12C36.2091 12 38 10.2091 38 8C38 5.79086 36.2091 4 34 4C31.7909 4 30 5.79086 30 8C30 10.2091 31.7909 12 34 12Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11 11L15 15" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M30 12L28 14" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M34 33.5L28 26" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 31L18 27" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path></svg>
    )
}

export const LogoLogin = () => {
    return (
        <svg style={ { stroke: '#599bfb' } } className="h-20 w-20 stroke-current text-primary fill-primary" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M10 42C13.3137 42 16 39.3137 16 36C16 32.6863 13.3137 30 10 30C6.68629 30 4 32.6863 4 36C4 39.3137 6.68629 42 10 42Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M38 44C41.3137 44 44 41.3137 44 38C44 34.6863 41.3137 32 38 32C34.6863 32 32 34.6863 32 38C32 41.3137 34.6863 44 38 44Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M22 28C26.4183 28 30 24.4183 30 20C30 15.5817 26.4183 12 22 12C17.5817 12 14 15.5817 14 20C14 24.4183 17.5817 28 22 28Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M34 12C36.2091 12 38 10.2091 38 8C38 5.79086 36.2091 4 34 4C31.7909 4 30 5.79086 30 8C30 10.2091 31.7909 12 34 12Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11 11L15 15" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M30 12L28 14" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M34 33.5L28 26" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 31L18 27" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path></svg>
    )
}

export const MenuIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block text-gray-500 w-6 h-6 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
    )
}

export const SearchIcon = () => {
    return (
        <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
        </svg>
    )
}

export const DashboardIcon = () => {
    return (
        <svg className="stroke-[#599bfb] stroke-base-content  mr-3 flex-shrink-0 h-6 w-6" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="6" y="6" width="14" height="14" rx="2" fill="none" stroke="" strokeWidth="4" strokeLinejoin="round"></rect>
            <rect x="6" y="28" width="14" height="14" rx="2" fill="none" stroke="" strokeWidth="4" strokeLinejoin="round"></rect>
            <path d="M35 20C38.866 20 42 16.866 42 13C42 9.13401 38.866 6 35 6C31.134 6 28 9.13401 28 13C28 16.866 31.134 20 35 20Z" fill="none" stroke="" strokeWidth="4" strokeLinejoin="round"></path>
            <rect x="28" y="28" width="14" height="14" rx="2" fill="none" stroke="" strokeWidth="4" strokeLinejoin="round"></rect>
        </svg>
    )
}

export const ReportIcon = () => {
    return (
        <svg className="stroke-[#599bfb] stroke-base-content mr-3 flex-shrink-0 h-6 w-6" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 24C5 34.4934 13.5066 43 24 43V26C24 24.8954 24.8954 24 26 24H43C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <rect x="30" y="30" width="12" height="12" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></rect></svg>
    )
}

export const ProfileIcon = () => {
    return (
        <svg className="stroke-[#599bfb] stroke-base-content mr-3 flex-shrink-0 h-6 w-6" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 20C27.866 20 31 16.866 31 13C31 9.13401 27.866 6 24 6C20.134 6 17 9.13401 17 13C17 16.866 20.134 20 24 20Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M6 40.8V42H42V40.8C42 36.3196 42 34.0794 41.1281 32.3681C40.3611 30.8628 39.1372 29.6389 37.6319 28.8719C35.9206 28 33.6804 28 29.2 28H18.8C14.3196 28 12.0794 28 10.3681 28.8719C8.86278 29.6389 7.63893 30.8628 6.87195 32.3681C6 34.0794 6 36.3196 6 40.8Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    )
}

export const ReportBookIcon = () => {
    return (
        <svg className="h-6 w-6 stroke-current text-white" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 7C5 5.34315 6.34315 4 8 4H32C33.6569 4 35 5.34315 35 7V44H8C6.34315 44 5 42.6569 5 41V7Z" fill="none" stroke="" strokeWidth="4" strokeLinejoin="round"></path><path d="M35 24C35 22.8954 35.8954 22 37 22H41C42.1046 22 43 22.8954 43 24V41C43 42.6569 41.6569 44 40 44H35V24Z" stroke="" strokeWidth="4" strokeLinejoin="round"></path><path d="M11 12H19" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11 19H23" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path></svg>
    )
}

export const BuildIcon = () => {
    return (
        <svg className="h-6 w-6 stroke-current text-white" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 44V6C8 5.44772 8.44772 5 9 5H29C29.5523 5 30 5.44772 30 6V44" fill="none"></path><path d="M8 44V6C8 5.44772 8.44772 5 9 5H29C29.5523 5 30 5.44772 30 6V44" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M30 15L40 20.9993V44" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 44H44" stroke="" strokeWidth="4" strokeLinecap="round"></path></svg>
    )
}

export const CopyIcon = () => {
    return (
        <svg className="h-6 w-6 text-white stroke-current" width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 38H41V16H30V4H13V38Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M30 4L41 16" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7 20V44H28" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M19 20H23" stroke="" strokeWidth="4" strokeLinecap="round"></path><path d="M19 28H31" stroke="" strokeWidth="4" strokeLinecap="round"></path></svg>
    )
}

export const DeviceIcon = () => {
    return (
        <svg className="inline-block w-8 h-8 stroke-[#599bfb]" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44 29H4V42H44V29Z" fill="none" stroke="" strokeWidth="2" strokeLinejoin="round"></path><path d="M35.5 38C36.8807 38 38 36.8807 38 35.5C38 34.1193 36.8807 33 35.5 33C34.1193 33 33 34.1193 33 35.5C33 36.8807 34.1193 38 35.5 38Z" fill="#333"></path><path d="M4 28.9998L9.03837 4.99902H39.0205L44 28.9998" stroke="" strokeWidth="2" strokeLinejoin="round"></path><path d="M10 35.5H27" stroke="" strokeWidth="2" strokeLinecap="round"></path></svg>
    )
}

export const HomeIcon = () => {
    return (
        <svg className="h-6 w-6 stroke-current text-white" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 12H44V20L42.6015 20.8391C40.3847 22.1692 37.6153 22.1692 35.3985 20.8391L34 20L32.6015 20.8391C30.3847 22.1692 27.6153 22.1692 25.3985 20.8391L24 20L22.6015 20.8391C20.3847 22.1692 17.6153 22.1692 15.3985 20.8391L14 20L12.6015 20.8391C10.3847 22.1692 7.61531 22.1692 5.39853 20.8391L4 20V12Z" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8 22.4889V44H40V22" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8 11.8222V4H40V12" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path><rect x="19" y="32" width="10" height="12" fill="none" stroke="" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></rect></svg>
    )
}

export const PlusIcon = () => {
    return (
        <svg className="mx-auto stroke-[#599bfb] text-white" width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6" width="36" height="36" rx="3" fill="none" strokeWidth="2" strokeLinejoin="round"></rect><path d="M24 16V32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16 24L32 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
    )
}

export const PlusIcon2 = () => {
    return (
        <svg className="stroke-[#599bfb] text-white" width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6" width="36" height="36" rx="3" fill="none" strokeWidth="2" strokeLinejoin="round"></rect><path d="M24 16V32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16 24L32 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
    )
}
