import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { toast } from 'react-toastify'
import { API } from '../../../api'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const validationSchema = yup.object({
    name: yup
        .string('Enter your name')
        .required('Name is required'),
    refresh: yup
        .string('Enter your Refresh(second)')
        .required('Refresh(second) is required'),
    map: yup
        .string('Select map image')
})

const AddSites = () => {
    const [ fileData, setfile ] = useState()
    const [ form, setForm ] = useState('')
    const [ loading, setloading ] = useState(null)

    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation()

    const getFormData = async () => {
        try {
            setloading(true)
            const ID = location.search.replace('?id:', '')
            if(ID){
                const response = await API(`/api/site/id/${ ID }`)
                setForm(response.site)
            }else{
                setForm('')
            }
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        } finally{
            setloading(false)
        }
    }

    useEffect(() => {
        getFormData()
    }, [])

    return (
        <>
            <div>
                <div className="">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {t('Create_Site')}
                    </h3>
                    {!loading?
                        <Formik
                            initialValues={ form !== '' ? {
                                name: form.name,
                                category: form.category,
                                refresh: form.refresh,
                                map: form.mapImage
                            } : {
                                name: '',
                                category: '',
                                refresh: '',
                                map: ''
                            } }
                            validationSchema={ validationSchema }
                            onSubmit={ async (values) => {
                                try {
                                    if(form === ''){
                                        const data = {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'multipart/form-data' },
                                            data: {
                                                file: fileData,
                                                name: values.name,
                                                category: values.category,
                                                refresh: values.refresh
                                            }
                                        }

                                        const response = await API('/api/site/register', data)
                                        if(response.success){
                                            toast.success('Site successfully added', { autoClose: 5000 })
                                            navigate(`/site/${ response.site._id }`)
                                        }
                                    }else{
                                        const data = {
                                            method: 'PATCH',
                                            headers: { 'Content-Type': 'multipart/form-data' },
                                            data: {
                                                file: fileData,
                                                name: values.name,
                                                category: values.category,
                                                refresh: values.refresh
                                            }
                                        }

                                        const response = await API(`/api/site/update/${ form._id }`, data)
                                        if(response.success){
                                            toast.info('Site successfully updated', { autoClose: 5000 })
                                            navigate('/site-management')
                                        }
                                    }

                                } catch (error) {
                                    toast.error('Internal Server Error', { autoClose: 5000 })
                                }
                            } }
                        >
                            {({ values,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <form onSubmit={ handleSubmit }>
                                    <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                                        <div>
                                            <h2 className="text-xl leading-6 font-bold text-[#599bfb]">{t('Site_Details')}</h2>
                                        </div>
                                        <div className='flex gap-[20px] sm:flex-row flex-col'>
                                            <div className='w-full'>
                                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                                    {t('Name')}
                                                </label>
                                                <div className="mt-1">
                                                    <input id="name" name="name" value={ values.name } onChange={ handleChange } type="name" autoComplete="name" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="name"/></div>
                                                </div>
                                            </div>
                                            <div className='w-full'>
                                                <label htmlFor="category" className="block text-sm font-medium text-[#5a7bad]">
                                                    {t('Category')}
                                                </label>
                                                <div className="mt-1">
                                                    <input id="category" name="category" value={ values.category } onChange={ handleChange } type="category" autoComplete="category" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="category"/></div>
                                                </div>
                                            </div>
                                            <div className='w-full relative'>
                                                <label htmlFor="refresh" className="block text-sm font-medium text-[#5a7bad]">
                                                    {t('Refresh_site')}
                                                </label>
                                                <div className="mt-1">
                                                    <input id="refresh" name='refresh' onChange={ handleChange } value={ values.refresh } placeholder='' type="number" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="refresh" /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex gap-[20px] sm:flex-row flex-col'>
                                            <div className='w-full'>
                                                <label htmlFor="map" className="block text-sm font-medium text-[#5a7bad]">
                                                    {t('Map')}
                                                </label>
                                                <div className="mt-1">
                                                    <input type="file" name='map' id='map' onChange={ (e) => setfile(e.currentTarget.files[ 0 ]) } accept="image/png, image/jpeg" />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="map" /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type='submit' className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>
                                            {t('Save')}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>:null}
                </div>
            </div>
        </>
    )
}

export default AddSites