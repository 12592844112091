import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { API } from '../../api';

const Logs = () => {
    const [ data, setData ] = useState([]);
    const [ loader, setLoader ] = useState(false)
    const { t } = useTranslation()

    const getSiteData = async () => {
        setLoader(true)
        const response = await API('/api/lists/systemlog')
        setData(response.logs)
        setLoader(false)
    }

    useEffect(() => {
        getSiteData()
    }, [])

    return (
        <>
            <div>
                <div className='flex gap-2 items-center'>
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {t('System_Log')}
                    </h3>
                </div>
                <div className="mt-6 overflow-x-auto">
                    <table className='w-[938px] overflow-x-auto lg:w-full divide-y divide-gray-200 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-[15px]'>
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    {t('TIMESTAMP')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    {t('TYPE')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    {t('MESSAGE')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    {t('ENV')}
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">

                                </th>
                            </tr>
                        </thead>
                        {!loader ?
                            <tbody className="bg-white divide-y divide-gray-200">
                                {data?.map((val) => <tr key={ val._id }>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.timestamp}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.type}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.message}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        {val.env}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-md font-semibold">
                                        <button className="text-[#599bfb] hover:text-primary-focus mr-3">{t('View')}</button>
                                    </td>
                                </tr>)}
                            </tbody> : null}
                    </table>
                </div>
            </div>
        </>
    )
}

export default Logs