import { toast } from 'react-toastify'
import { API } from '../../api'
import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';

const StatusCard = ({ id, dashboardId, refresh, toDate, fromDate }) => {
    // console.log('status card id')
    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(true)

    const getChartData = async (loading) => {
        setLoading(loading)
        try{
            const data = {
                method: 'POST',
                data : {
                    _id : dashboardId,
                    cardId : id,
                    fromDate,
                    toDate
                }
            }
            // console.log(data)
            const chartAPI = await API('/lightdata', data)
            setLoading(false)
            setData(chartAPI.chartData.data)
        }catch (error) {
            setLoading(false)
            toast.error(error, { autoClose: 5000 })
        }

    }

    useEffect(() => {
        if ((fromDate !== '' && toDate !== '') || (fromDate == '' && toDate == '')){
            getChartData(true)
            const interval = setInterval(() => {
                if(refresh){
                    getChartData(false)
                }
            }, 10000)
            return () => clearInterval(interval);
        }
    }, [ toDate, fromDate ])

    const setFields = () => {
        const fields = []
        {data?.map((items)=>{
            if (typeof items === Array){
                {items?.map((item)=>{
                    fields.push(
                        <span
                            style={ { color: items?.buckets?.y == 0 ? items?.textFalseColor : items?.textTrueColor } }
                            className={ 'text-normal font-bold text-center p-2' }>{ items?.buckets?.y == 0 ? item?.falseText : item?.trueText }</span>
                    )
                }) }
            }else{
                fields.push(
                    <span
                        style={ { color: items?.buckets?.y == 0 ? items?.textFalseColor : items?.textTrueColor } }
                        className={ 'text-normal font-bold text-center p-2' }>{ items?.buckets?.y == 0 ? items?.falseText : items?.trueText }</span>
                )

            }

        }) }
        return fields
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center">
                <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
            </div>
        )
    }
    else
        return <div style={ { background: data?.[ 0 ]?.buckets?.y == 0? data?.[ 0 ]?.falseColor  : data?.[ 0 ]?.trueColor } } className='p-3 rounded text-center mb-3'>
            {setFields()}
        </div>
}

export default StatusCard