/* eslint-disable camelcase */
import ChartLine from './ChartLine'
import React, { useEffect, useState } from 'react'
import { API } from '../../api'
import BarLoader from 'react-spinners/BarLoader';
import moment from 'moment';
import { use } from 'i18next';

const Linechart = ({ id, dashboardId, refresh, toDate, fromDate, setSelectedCards, selectedCards, selectingCards, val }) => {
    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ dateTooLong, setDateTooLong ] = useState(false)
    const [ isCheck, setIsChecked ] = useState(false)

    useEffect(() => {
        if (!selectingCards){
            setIsChecked(false)
        }
    }, [selectingCards])

    const setChartData = async (loading) => {
        const toDate = moment(localStorage.getItem('toDate'));
        const fromDate = moment(localStorage.getItem('fromDate'));
        const diff = toDate.diff(fromDate, 'days')
        if(diff > 3 && val.groups[ 0 ].devices[ 0 ].group_as == '1m') {
            setDateTooLong(true)
            setLoading(false)
        }
        else if (localStorage.getItem('toDate') == null && localStorage.getItem('fromDate') == null && val.groups[ 0 ].devices[ 0 ].group_as == '1m'){
            setDateTooLong(true)
            setLoading(false)
        }
        else{
            setDateTooLong(false)
            setLoading(loading)
            const data = {
                method: 'POST',
                data : {
                    _id : dashboardId,
                    cardId : id,
                    fromDate,
                    toDate
                }
            }

            const chartArray = await API('/lightData', data)
            setLoading(false)
            setData(chartArray.chartData)
        }

    }

    useEffect(() => {
        if ((fromDate !== '' && toDate !== '') || (fromDate == '' && toDate == '')){
            setChartData(true)
            const interval = setInterval(() => {
                console.log(refresh)
                if(refresh){
                    setChartData(false)
                }
            }, 10000)
            return () => clearInterval(interval);
        }
    }, [ fromDate, toDate ])

    useEffect(() => {
        for(const val of selectedCards){
            if(val._id === id){
                setIsChecked(true)
            }
        }
    }, [selectedCards])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-[35vh]">
                <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
            </div>
        )
    }
    else if(dateTooLong){
        return<div>
            <p className='text-center text-xl font-medium text-[#5a7bad]'>Date range for 1 minute interval should be within 4 days.</p>
        </div>
    }
    else
        return<div className='chart border h-[35vh]'>
            {selectingCards && <input type="checkbox" className="absolute top-2 left-2" onChange={ () => setSelectedCards([ ...selectedCards, val ]) } checked={ isCheck }/>}
            <ChartLine id={ id } data={ data } val={ val } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards }/>
        </div>
}

export default Linechart
