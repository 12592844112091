/* eslint-disable no-unused-vars */
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CreateNotification from '../components/Notification/CreateNotification/CreateNotification';
import Dashboard from '../components/Dashboard/Dashboard';
import AddDevice from '../components/DeviceManagement/AddDevice/AddDevice';
import DeviceManagement from '../components/DeviceManagement/DeviceManagement';
import ViewDevices from '../components/DeviceManagement/ViewDevices/ViewDevices';
import Download from '../components/Download/Download';
import AddFloors from '../components/Floors/AddFloors/AddFloors';
import FloorDashboard from '../components/Floors/FloorDashboard';
import Header from '../components/Header/Header';
import Login from '../components/Login/Login';
import Logs from '../components/Logs/Logs'
import AddRole from '../components/MemberManage/AddRole/AddRole'
import EditMember from '../components/MemberManage/EditMember/EditMember';
import MemberManage from '../components/MemberManage/MemberManage';
import Roles from '../components/MemberManage/Roles/Roles'
import Notification from '../components/Notification/Notification';
import Profile from '../components/Profile/Profile';
import ReportDetails from '../components/ReportDetails/ReportDetails';
import Reports from '../components/GenerateReports/GenerateReports';
import Sidebar from '../components/Sidebar/Sidebar';
import AddSites from '../components/SiteManagement/AddSites.js/AddSites'
import SiteManagement from '../components/SiteManagement/SiteManagement'
import AuthContext from '../context'
import { localStorageGet } from '../utils/localstorage'
import AddCard from '../components/DashboardCards/AddCards/AddCard';
import Cards from '../components/DashboardCards/ViewCards/Cards';
import AddDashboard from '../components/DashboardCards/AddDashboard/AddDashboard';
import Linechart from '../components/Charts/Linechart';
import CreateProfile from '../components/Notification/CreateProfile/CreateProfile';
import EditProfile from '../components/Notification/EditProfile/EditProfile'
import ResetPassword from '../components/ResetPassword/ResetPassword';
import AddNotification from '../components/DeviceManagement/AddNotification/AddNotification';
import IpMeter from '../components/IpMeter/IpMeter';
import QotMqtt from '../components/QotMqtt/QotMqtt';

const PageRoutes = () => {
    const [ open, setOpen ] = useState(false);
    const route = useLocation();
    const navigate=useNavigate()
    useEffect(() => {
    }, [route]);

    const [ authstatus, setauthstatus ] = useState(localStorage.getItem('token'))

    const JWTverify = () => {
        try {
            let token =  localStorageGet('token')
            token = JSON.parse(token)
            const decodedToken = jwtDecode(token?.jwt)
            const currentDate = new Date();

            if (!decodedToken) {
                navigate('/login')
            }
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                localStorage.clear();
                navigate('/login')
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('error', error)
        }
    }

    useEffect(() => {
        JWTverify()
    })

    const login = () => {
        setauthstatus(true)
    }
    const logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('toDate')
        localStorage.removeItem('fromDate')
        setauthstatus(false)
    };
    return (
        <AuthContext.Provider value={ {
            logout: logout,
            login: login,
        } }>
            {(route.pathname !== '/login' && route.pathname !== '/resetpassword') && <Sidebar open={ open } setOpen={ setOpen } />}
            <div className={ `${ open ? 'lg:w-[calc(100%-300px)] w-full ml-[auto] transition-all' : 'w-full' } ${ route.pathname === '/login' && 'set-width' }` }>
                {(route.pathname !== '/login' && route.pathname !== '/resetpassword') && <Header setOpen={ setOpen } />}
                <div className={ `pt-10 px-[20px] lg:px-[50px] ${ route.pathname === '/login' && 'remove-px' }` }>
                    <Routes>
                        <Route path="/" element={ <Navigate to='/dashboard' /> } />
                        <Route path="/login" element={ <Login /> } />
                        <Route path="/resetpassword" element={ <ResetPassword /> } />
                        <Route path="/dashboard" element={ authstatus ? <Dashboard /> : <Navigate to='/login' /> } />
                        <Route path="/profile" element={ authstatus ? <Profile /> : <Navigate to='/login' /> } />
                        <Route path="/reports" element={ authstatus ? <Reports /> : <Navigate to='/login' /> } />
                        <Route path="/reports/details" element={ authstatus ? <ReportDetails /> : <Navigate to='/login' /> } />
                        <Route path="/notifications" element={ authstatus ? <Notification /> : <Navigate to='/login' /> } />
                        <Route path="/notifications/new" element={ authstatus ? <CreateNotification /> : <Navigate to='/login' /> } />
                        <Route path="/download" element={ authstatus ? <Download /> : <Navigate to='/login' /> } />
                        <Route path="/devices" element={ authstatus ? <DeviceManagement /> : <Navigate to='/login' /> } />
                        <Route path="/membermanage" element={ authstatus ? <MemberManage /> : <Navigate to='/login' /> } />
                        <Route path="/management/addmember" element={ authstatus ? <Profile /> : <Navigate to='/login' /> } />
                        <Route path="/site-management" element={ authstatus ? <SiteManagement /> : <Navigate to="/login" /> } />
                        <Route path="/addsite" element={ authstatus ? <AddSites /> : <Navigate to="/login" /> } />
                        <Route path="/logs" element={ authstatus ? <Logs /> : <Navigate to="/login" /> } />
                        <Route path="/ipmeter" element={ authstatus ? <IpMeter /> : <Navigate to="/ipmeter" /> } />
                        <Route path="/qotmqtt" element={ authstatus ? <QotMqtt /> : <Navigate to="/qotmqtt" /> } />
                        <Route path="/membermanage/roles" element={ authstatus ? <Roles /> : <Navigate to="/login" /> } />
                        <Route path="/membermanage/roles/add" element={ authstatus ? <AddRole /> : <Navigate to="/login" /> } />
                        <Route path="/management/editmember" element={ authstatus ? <EditMember /> : <Navigate to="/login" /> } />
                        <Route path="/site/:id" element={ authstatus ? <FloorDashboard /> : <Navigate to="/login" /> } />
                        <Route path="/site/addfloor" element={ authstatus ? <AddFloors /> : <Navigate to="/login" /> } />
                        <Route path="/device/add" element={ authstatus ? <AddDevice /> : <Navigate to="/login" /> } />
                        <Route path="/device/management/:id" element={ authstatus ? <ViewDevices /> : <Navigate to="/login" /> } />
                        <Route path="/dashboard/card" element={ authstatus ? <Cards /> : <Navigate to="/login" /> } />
                        <Route path="/card/new" element={ authstatus ? <AddCard /> : <Navigate to="/login" /> } />
                        <Route path="/dashboard/new" element={ authstatus ? <AddDashboard /> : <Navigate to="/login" /> } />
                        <Route path="/linechart" element={ authstatus ? <Linechart /> : <Navigate to="/login" /> } />
                        <Route path="/addprofile" element={ authstatus ? <CreateProfile /> : <Navigate to="/login" /> } />
                        <Route path="/editprofile/:id" element={ authstatus ? <EditProfile /> : <Navigate to="/login" /> } />
                        <Route path="/device/notification/add" element={ authstatus ? <AddNotification /> : <Navigate to="/login" /> } />

                    </Routes>
                </div>
            </div>
        </AuthContext.Provider>
    )
}

export default PageRoutes
