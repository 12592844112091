import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { IoMdRemoveCircle } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { API } from '../../../api';

const EditProfile = () => {
    const navigate = useNavigate()
    const location =  useLocation()
    const [ formData, setFormData ] = useState({
        name:'',
        companyName:'',
        whatsapp:[],
        email:[],
        sms:[]
    })
    const { t } = useTranslation()

    const getFormData = async() =>{
        const _id = location.search.replace('?', '')
        const response = await API(`/api/list/notification/${ _id }`)
        setFormData({
            name:response.notification.name,
            companyName:response.notification.company_name,
            companyId:response.notification.company_id,
            whatsapp:response.notification.whatsapp.map((val) => val),
            email:response.notification.email.map((val) => val),
            sms:response.notification.sms.map((val) => val)
        })
    }

    const handleSubmit = async() =>{
        try{
            const _id = location.search.replace('?', '')
            const data ={
                method: 'PATCH',
                data:{ name: formData.name,
                    company_name:formData.companyName,
                    company_id:formData.company_id,
                    sms:formData.sms.map((val) => val),
                    whatsapp:formData.whatsapp.map((val) => val),
                    email:formData.email.map((val) => val)
                }
            }
            const response = await API(`/api/update/notification/${ _id }`, data)
            if(response.success){
                toast.info(response.message, { autoClose: 5000 })
                navigate('/notifications')
            }
        }catch(error){
            toast.error(error, { autoClose: 5000 })
        }
    }
    const handleWhatsappOnChange = (e, id) =>{
        const whatsapps = [...formData.whatsapp]
        whatsapps[ id ].value = e;
        setFormData({ ...formData, whatsapp:whatsapps })
    }
    const handleRemoveWhatsappFields = (index) => {
        const value  = [...formData.whatsapp];
        value.splice(index, 1);
        setFormData({ ...formData, whatsapp:value });
    }

    const handleEmailOnChange = (e, id) =>{
        const emails = [...formData.email]
        emails[ id ].value = e;
        setFormData({ ...formData, email:emails })
    }
    const handleRemoveEmailFields = (index) => {
        const value  = [...formData.email];
        value.splice(index, 1);
        setFormData({ ...formData, email:value });
    }
    const handleSmsOnChange = (e, id) =>{
        const sms = [...formData.sms]
        sms[ id ].value = e;
        setFormData({ ...formData, sms:sms })
    }
    const handleRemoveSmsFields = (index) => {
        const value  = [...formData.sms];
        value.splice(index, 1);
        setFormData({ ...formData, sms:value });
    }

    useEffect(() => {
        getFormData()
    }, [])

    return (
        <div>
            <div>
                <h4 className='text-2xl leading-6 font-bold text-[#5a7bad] '>{t('Edit_Profile')}</h4>
            </div>
            <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                <h4 className='text-xl leading-6 font-bold text-[#599bfb]'>{t('Profile_Detalis')}</h4>
                <div className='md:flex block gap-5 w-full'>
                    <div className='w-full my:my-0 my-[10px]'>
                        <div>
                            <label htmlFor="" className='block text-sm font-medium text-[#5a7bad]'>{t('Name')}</label>
                        </div>
                        <input type="text"  value={ formData.name }className='appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-2' onChange={ (e) => setFormData({ ...formData, name: e.target.value }) }/>
                    </div>
                </div>
                <div className='md:flex block gap-5 items-start'>
                    <div className='w-full md:my-0 my-[10px] shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] p-[20px]'>
                        <button className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white px-[10px] flex justify-center mb-4'
                            onClick={ () => setFormData( { ...formData, whatsapp:[ ...formData.whatsapp, { value:'' }] }) }
                        >
                            {t('Whatsapp')} + </button>
                        {formData.whatsapp.map((val, i) =>
                            <div key={ i } className='flex items-center gap-3'>
                                <input type="text" value={ val.value } className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-[10px]'
                                    onChange={ (e) => handleWhatsappOnChange(e.target.value, i) }
                                />
                                <button className='text-[20px]' onClick={ () => handleRemoveWhatsappFields(i) }>
                                    <IoMdRemoveCircle className='text-[#599bfb]' />
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='w-full md:my-0 my-[10px] shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] p-[20px]'>
                        <button
                            onClick={ () => setFormData( { ...formData, email:[ ...formData.email, { value:'' }] }) }
                            className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white px-[10px] flex justify-center mb-4'>{t('Email')} + </button>
                        {formData.email.map((val, i) =>
                            <div key={ i } className='flex items-center gap-3'>
                                <input type="text" value={ val.value } className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-[10px]'
                                    onChange={ (e) => handleEmailOnChange(e.target.value, i) }
                                />
                                <button className='text-[20px]' onClick={ () => handleRemoveEmailFields(i) }>
                                    <IoMdRemoveCircle className='text-[#599bfb]' />
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='w-full md:my-0 my-[10px] shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] p-[20px]'>
                        <button
                            onClick={ () => setFormData( { ...formData, sms:[ ...formData.sms, { value:'' }] }) }
                            className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white px-[10px] flex justify-center mb-4'>{t('SMS')} + </button>
                        {formData.sms.map((val, i) =>
                            <div key={ i } className='flex items-center gap-3'>
                                <input type="text" value={ val.value } className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-[10px]'
                                    onChange={ (e) => handleSmsOnChange(e.target.value, i) }
                                />
                                <button className='text-[20px]' onClick={ () => handleRemoveSmsFields(i) }>
                                    <IoMdRemoveCircle className='text-[#599bfb]' />
                                </button>
                            </div>
                        )}

                    </div>
                </div>
                <div>
                    <button type='submit' className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center' onClick={ () => handleSubmit(formData) }>{t('Save')}</button>
                </div>
            </div>
        </div>
    )
}

export default EditProfile
