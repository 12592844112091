import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useContext, useEffect, useState  } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API } from '../../api'
import { DashboardIcon, Logo, ProfileIcon, ReportIcon } from '../../Assets/AllSvg'
import AuthContext from '../../context'
import { localStorageGet, localStorageSet } from '../../utils/localstorage'

const Sidebar = ({ open, setOpen }) => {
    const route = useLocation()
    const auth = useContext(AuthContext)
    const userId = localStorageGet('user_id')
    const [ profile, setProfile ] = useState({})
    const userType = localStorageGet('type')
    const [ loading, setloading ] = useState(null)
    const [ siteData, setSiteData ] = useState([])
    const [ searchData, setSearchData ] = useState([])
    const[ filter, setFilter ] = useState('')
    const { t } = useTranslation()
    const URL =  process.env.REACT_APP_BASE_URL

    const getSiteData = async () => {
        const response = await API('/api/site/lists')
        if (userType == 'super-admin') {
            setSiteData(response.sites)
            setSearchData(response.sites)
        }
        else{
            const sitesData = JSON.parse(localStorageGet('sites'))
            // console.log('response.sites: '+JSON.stringify(response.sites))
            const displayList = response.sites.filter((val) => sitesData.includes(val._id))
            setSiteData(displayList)
            setSearchData(displayList)
            const userId = localStorageGet('user_id')
            const loginUser = await API(`/api/list/member/${ JSON.parse(userId) }`)
            localStorageSet('permissions', JSON.stringify(loginUser.member.permissions))
        }
    }

    const handleOnChange = (value) =>{
        if(value === ''){
            setSiteData(searchData)
        }else{
            const filterResult =  searchData.filter((val) => val.name.toLowerCase().includes(value.toLowerCase()))
            setSiteData(filterResult)
        }
        setFilter(value)
    }

    const getMemberbyId = async() =>{
        try{
            setloading(true)
            const response =await API(`/api/list/member/${ JSON.parse(userId) }`)
            setProfile({
                logo:response.member.logo,
                webName:response.member.web_name
            })
        }catch(error){
            toast.error(error, { autoClose: 5000 })
        }finally{
            setloading(false)
        }
    }

    useEffect(() => {
        getSiteData()
        getMemberbyId()
    }, [])

    return (
        <>{!loading ?
            <Transition.Root show={ open } as={ Fragment }>
                <Dialog as="div" className="relative z-10" onClose={ () => setOpen(false) }>
                    <div className="fixed inset-0 left-0 opacity-[.2]" />
                    <div className="fixed inset-0 left-0 overflow-hidden">
                        <div className="absolute inset-0 left-0 overflow-hidden">
                            <div className="pointer-events-none fixed h-screen left-0 flex max-w-full">
                                <Transition.Child
                                    as={ Fragment }
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-0"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-[-500px]"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-[300px] mr-[50px]">
                                        <div className="flex h-full flex-col overflow-y-auto sidebar rounded-r-[35px] py-5 bg-[linear-gradient(20deg,_rgba(218,230,242,1)_0%,_rgba(237,242,248,1)_100%)] shadow-[35px_0px_25px_-12px_rgba(71,71,71,.2)]">
                                            <div className="flex items-center flex-shrink-0 px-7 mb-2">
                                                {profile.logo === '' ?
                                                    <Link to="/" className='outline-none'>
                                                        <Logo />
                                                    </Link>:
                                                    <img src={ URL+profile.logo } alt="" className='w-[40px]' />
                                                }
                                                <span className="ml-4 font-medium text-2xl inline uppercase text-[#599bfb]">{profile.webName}</span>
                                            </div>
                                            <div className="mt-5 flex-grow flex flex-col">
                                                <div className="flex-1 px-4 space-y-8 bg-neutral ">
                                                    <div className="space-y-1">
                                                        <Link to="/dashboard" onClick={ ()=>setOpen(false) } className={ `${ route.pathname === '/dashboard' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                            <DashboardIcon />
                                                            {t('Dashboard')}
                                                        </Link>
                                                        {(userType === 'super-admin') &&<Link to="/reports" onClick={ ()=>setOpen(false) } className={ `${ route.pathname === '/reports' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                            <ReportIcon />
                                                            {t('Reports')}
                                                        </Link>}
                                                        <Link to="/profile" onClick={ ()=>setOpen(false) } className={ `${ route.pathname === '/profile' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                            <ProfileIcon />
                                                            {t('Profile')}
                                                        </Link>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <h3 className="px-4 text-[16px] font-bold text-gray-500 uppercase tracking-wider">
                                                            {t('Admin_Only')}
                                                        </h3>
                                                        <div className='pt-1'>
                                                            {(userType === 'super-admin' ) &&<Link to="/download" onClick={ ()=>setOpen(false) } className={ `${ route.pathname === '/download' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                {t('Download')}
                                                            </Link>}
                                                            {(userType === 'super-admin') &&<Link to="/notifications" onClick={ ()=>setOpen(false) } className={ `${ route.pathname === '/notifications' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                {t('Notifications')}
                                                            </Link>}
                                                            {userType === 'super-admin' &&
                                                            <>
                                                                <Link to="/devices" onClick={ ()=>setOpen(false) } className={ `${ route.pathname === '/devices' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                    {t('Devices_Management')}
                                                                </Link>
                                                                <Link to="/site-management" onClick={ () => setOpen(false) } className={ `${ route.pathname === '/site-management' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                    {t('Site_Management')}
                                                                </Link>
                                                            </>}
                                                            {(userType === 'super-admin' || userType === 'admin') &&
                                                            <>
                                                                <Link to="/membermanage" onClick={ () => setOpen(false) } className={ `${ route.pathname === '/' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                    {t('Member_Management')}
                                                                </Link>
                                                            </>}
                                                            {(userType === 'super-admin' ) &&
                                                            <Link to="/logs" onClick={ () => setOpen(false) } className={ `${ route.pathname === '/logs' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                {t('System_Log')}
                                                            </Link>}
                                                            {(userType === 'super-admin' ) &&
                                                            <Link to="/ipmeter" onClick={ () => setOpen(false) } className={ `${ route.pathname === '/ipmeter' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                {t('IP_and_Meter_Settings')}
                                                            </Link>}
                                                            {/* {(userType === 'super-admin' ) &&
                                                            <Link to="/generatereports" onClick={ () => setOpen(false) } className={ `${ route.pathname === '/ipmeter' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                {t('Generate_Reports')}
                                                            </Link>} */}
                                                            {(userType === 'super-admin' ) &&
                                                            <Link to="/qotmqtt" onClick={ () => setOpen(false) } className={ `${ route.pathname === '/qotmqtt' ? 'border-[#e8e8e8] font-medium shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)]' : 'font-regular border-[transparent]' } hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45)] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md rounded-full` }>
                                                                {t('QOT_MQTT')}
                                                            </Link>}
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <h3 className="px-4 text-md font-bold text-gray-500 uppercase tracking-wider">
                                                            {t('SITE')}
                                                        </h3>
                                                        <input type="text" id="filter-text-box" placeholder="Search" className='h-[35px] mt-4 focus:shedow-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm'
                                                            value={ filter }
                                                            onInput={ (e) => handleOnChange(e.target.value) }
                                                        />
                                                        <div className='pt-1'>
                                                            {siteData && siteData.map((val) =>
                                                                <Link key={ val._id } to={ `/site/${ val._id }` } onClick={ () => setOpen(false) } className="hover:transition-all hover:font-medium transition-all hover:border-[#e8e8e8] border-[1px] border-[transparent] hover:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.45);] hover:bg-[#edf4fe] text-[#7d8791] group flex items-center px-4 py-2 text-md font-regular rounded-full">
                                                                    {val.name}
                                                                </Link>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0 flex p-4 px-[50px] mt-[20px]">
                                                <button onClick={ () => auth.logout() } className="w-full group flex text-center items-center px-4 py-3 text-md rounded-md text-white font-medium bg-[#599bfb]">
                                                    <span  className="truncate uppercase w-full text-center">
                                                        {t('Logout')}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            :''}
        </>
    )
}

export default Sidebar