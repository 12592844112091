import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ReportChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: 'Desktops',
                data: [ 5, 5, 5, 5, 5, 62, 69, 91, 148, 90, 20, 30 ]
            }],
            options: {
                chart: {
                    height: 600,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight',
                    width:'3'
                },
                title: {
                    text: '',
                    align: 'left',
                },
                grid: {
                    padding: { left: 0, right: 0, top: 0, bottom: -10 },
                    row: {
                        colors: [ '#f3f3f3', 'transparent' ], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' ],
                },
                responsive: [
                    {
                        breakpoint: 700,
                        options: {
                            chart: {
                                height: 300,
                            }
                        },
                    },
                ]
            },

        };
    }

    render() {
        return (

            <div id="chart">
                <ReactApexChart options={ this.state.options } series={ this.state.series } type="line" height={ 600 } />
            </div>

        );
    }
}

export default ReportChart