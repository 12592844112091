import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API } from '../../api'
import { Logo, MenuIcon } from '../../Assets/AllSvg'
import avatar from '../../Assets/Images/avatar.png'
import AuthContext from '../../context'
import { localStorageGet } from '../../utils/localstorage'

const Header = ({ setOpen }) => {
    const [ fontSize, setFontSize ] = useState('')
    const [ logo, setLogo ] = useState('')
    const auth = useContext(AuthContext)
    const { t } = useTranslation()
    const userId = localStorageGet('user_id')
    const [ loading, setloading ] = useState(null)
    const URL =  process.env.REACT_APP_BASE_URL

    const handleChange = (e) =>{
        setFontSize(e)
    }

    const fontSizes = () =>{
        if(fontSize === '12'){
            document.body.classList.add('fs-12');
            document.body.classList.remove('fs-18');
            document.body.classList.remove('fs-15');
        }else if(fontSize === '15'){
            document.body.classList.add('fs-15');
            document.body.classList.remove('fs-12');
            document.body.classList.remove('fs-18');
        }else if(fontSize === '18'){
            document.body.classList.add('fs-18');
            document.body.classList.remove('fs-12');
            document.body.classList.remove('fs-15');
        }else{
            document.body.classList.remove('fs-18');
            document.body.classList.remove('fs-12');
            document.body.classList.remove('fs-15');
        }
    }

    const getMemberbyId = async() =>{
        try{
            setloading(true)
            const response =await API(`/api/list/member/${ JSON.parse(userId) }`)
            setLogo(response.member.logo)

        }catch(error){
            toast.error(error, { autoClose: 5000 })
        }finally{
            setloading(false)
        }
    }

    useEffect(() => {
        fontSizes()
        getMemberbyId()
    }, [fontSize])

    return (
        <>
            {!loading ?
                <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-2">
                    <div className="relative flex justify-between lg:gap-8">
                        <div className="flex w-full">
                            {logo === '' ?
                                <Link to="/">
                                    <Logo />
                                </Link>:
                                <div className="flex-shrink-0 flex items-center">
                                    <img src={ URL+logo } alt="" className='w-[40px]' />
                                </div>
                            }
                            <div className="flex items-center ml-4">
                                <label htmlFor="my-drawer-3" className="p-2 hover:transition-all transition-all rounded-md hover:bg-[#d8dada] btn btn-square cursor-pointer" onClick={ ()=>setOpen(true) }>
                                    <MenuIcon />
                                </label>
                            </div>
                        </div>
                        <div className='flex items-center w-full justify-end'>
                            <div className="md:px-8 lg:px-0 max-w-[200px] flex items-baseline justify-center gap-3 w-full">
                                <button className='select-text-18' onClick={ ()=> handleChange('18') }>A</button>
                                <button className='select-text-15' onClick={ () => handleChange('15') }>A</button>
                                <button className='select-text-12' onClick={ () => handleChange('12') }>A</button>
                            </div>
                            <div className="hidden lg:flex lg:items-center lg:justify-end">
                                <div className="flex-shrink-0 relative ml-5">
                                    <div>
                                        <Link to="/" className="flex focus:outline-none font-medium text-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-primary" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                            <span className="sr-only">Language</span>
                                            ENG
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex-shrink-0 relative ml-5">
                                    <div>
                                        <Link to="/profile" className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" id="user-menu-button">
                                            <img className="h-8 w-8 rounded-full" src={ avatar } alt="avatar"/>
                                        </Link>
                                    </div>
                                </div>
                                <button onClick={ () => auth.logout() } className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-semibold rounded-md shadow-sm text-white bg-[#599bfb] focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                                    {t('Logout')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>:null}
        </>
    )
}

export default Header