// import { AgChartsReact } from 'ag-charts-react'
import GaugeChart from 'react-gauge-chart';
import { toast } from 'react-toastify';
import { API } from '../../api';
import BarLoader from 'react-spinners/BarLoader';
import React, { useState, useEffect } from 'react';

const GaugechartDemo = ({ id, dashboardId, maxWidth, refresh, toDate, fromDate }) => {
    const [ options, setOptions ] = useState({})
    const [ loading, setLoading ] = useState(true)

    const getHitsScore = async (loading) => {
        setLoading(loading)
        try{
            const data = {
                method: 'POST',
                data : {
                    _id : dashboardId,
                    cardId : id,
                    fromDate,
                    toDate
                }
            }
            const chartAPI = await API('/chartdata', data)
            setLoading(false)
            setOptions(chartAPI.chartData.data[ 0 ])

        }catch (error) {
            setLoading(false)
            toast.error(error, { autoClose: 5000 })
        }

    }

    useEffect(() => {
        if ((fromDate !== '' && toDate !== '') || (fromDate == '' && toDate == '')){
            getHitsScore(true)
            const interval = setInterval(() => {
                if(refresh){
                    getHitsScore(false)
                }
            }, 10000)
            return () => clearInterval(interval);
        }
    }, [ toDate, fromDate ])

    if (loading) {
        return (
            <div className="flex justify-center items-center">
                <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
            </div>
        )
    }
    else
        return  (
            <div className='ag-theme-alpine'>
                <div className="coverage-chart w-full">
                    {/* <AgChartsReact options={ options } />
                 */}
                    <GaugeChart
                        style={ { width: `${ maxWidth }%`, height: '50%', margin:'auto' } }
                        id="gauge-chart3"
                        nrOfLevels={ 20 }
                        colors={ [ 'green', 'orange', 'red' ] }
                        arcWidth={ 0.3 }
                        percent={ options.percentage }
                        textColor={ 'black' }
                    // hideText={true} // If you want to hide the text
                    />
                </div>
            </div>
        );
}

export default GaugechartDemo