import React, { useState, useEffect } from 'react'
import Type from '../../Json/IOTtype.json'
import { API, HostAPI } from '../../../api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const AddDevice = () => {
    const [ selectHost, setSelectHost ] = useState(false)
    const [ selectType, setSelectType ] = useState(false)
    const [ selectSite, setSelectSite ] = useState(false)
    const [ selectFloor, setSelectFloor ] = useState(false)
    const [ siteData, setSiteData ] = useState([])
    const [ hostList, setHostData ] = useState([])
    const [ host, setHost ] = useState('')
    const [ type, setType ] = useState('')
    const [ site, setSite ] = useState('')
    const [ floorVal, setFloor ] = useState('')
    const [ deviceName, setName ] = useState('')
    const [ id, setId ] = useState('')
    const [ floorJson, setFloorJson ] = useState([])
    const [ form, setForm ] = useState('')
    const [ deviceForm, setDeviceForm ] = useState('')
    const [ deviceID, setDeviceID ] = useState('')
    const [ siteID, setSiteID ] = useState('')
    const [ loader, setLoader ] = useState(false)
    const { t } = useTranslation()

    const navigate = useNavigate()

    const getSiteData = async () => {
        setLoader(true)
        const response = await API('/api/site/lists')
        setSiteData(response.sites)

        const hostData = await HostAPI()
        if(hostData.success){
            setHostData(hostData.iot_host.rows)
        }else{
            toast.error('Something went wrong', { autoClose: 5000 })
            setLoader(false)
        }
        setLoader(false)

    }

    const handleOnchangeSiteData = async (val, i) => {
        setSelectSite(i);
        setSite(val.name);
        setFloorJson(val.floors);
        if(deviceForm === ''){
            const response = await API(`/api/site/id/${ val._id }`)
            setForm(response.site)
        }else{
            deviceForm.floors.map((floor) => {
                if(floorVal === floor.name){
                    floor.devices = floor.devices.filter((val) => val._id !== deviceID)
                }
                return floor.devices
            })

            const data = {
                method: 'PATCH',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: deviceForm
            }
            const response = await API(`/api/site/update/${ siteID }`, data)
            if(response.success){
                const siteResponse = await API(`/api/site/id/${ val._id }`)
                setForm(siteResponse.site)
                selectFloor(false)
            }else{
                toast.error('Internal Server Error', { autoClose: 5000 })
            }
        }
    }

    const handleOnchangeFloorData = async (val, i) => {
        setSelectFloor(i);
        setFloor(val.name)
        if(siteID !== form._id){
            const floorData = form.floors.map((floor) => {
                if(floor.name === val.name){
                    const data = {
                        iotHost: host,
                        iotType: type,
                        name: deviceName,
                        iotId: id,
                        floor: val.name,
                        isDelete:false
                    }
                    floor.devices = [ ...floor.devices, data ]
                }
                return floor
            })
            setForm({ ...form, floors: floorData })

        } else {
            const floorData = form.floors.map((floor) => {
                if(floorVal === floor.name){
                    floor.devices = floor.devices.filter((val) => val._id !== deviceID)
                }
                if(floor.name === val.name){
                    const data = {
                        iotHost: host,
                        iotType: type,
                        name: deviceName,
                        iotId: id,
                        floor: val.name,
                        isDelete:false
                    }
                    floor.devices = [ ...floor.devices, data ]
                }
                return floor
            })
            setForm({ ...form, floors: floorData })
        }
    }

    const submitDevice = async () => {
        try{
            if(deviceName !== '' && id !== ''){
                if(host !== '' && site !== '' && floorVal !== ''){
                    if(deviceForm === ''){
                        const data = {
                            method: 'PATCH',
                            headers: { 'Content-Type': 'multipart/form-data' },
                            data: form
                        }
                        const response = await API(`/api/site/update/${ form._id }`, data)
                        if(response.success){
                            toast.success('Device successfully added', { autoClose: 5000 })
                            navigate('/devices')
                        }
                    } else {
                        const floorData = form.floors.map((floor) => {
                            if(floor.name === floorVal){
                                floor.devices.map((device) => {
                                    if(device._id === deviceID){
                                        device.iotHost = host,
                                        device.iotType = type,
                                        device.name = deviceName,
                                        device.iotId = id,
                                        device.floor = floorVal
                                    }})
                            }
                            return floor
                        })

                        const data = {
                            method: 'PATCH',
                            headers: { 'Content-Type': 'multipart/form-data' },
                            data: { ...form, floors: floorData }
                        }
                        const response = await API(`/api/site/update/${ form._id }`, data)
                        if(response.success){
                            toast.info('Device successfully updated', { autoClose: 5000 })
                            navigate(`/device/management/${ form._id }`)
                        }
                    }
                } else {
                    toast.error('The IOT host, IOT type, site and floor field is required.', { autoClose: 5000 })
                }
            } else{
                toast.error('The device name and IOT id field is required.', { autoClose: 5000 })
            }
        } catch (error) {
            toast.error('Internal Server Error', { autoClose: 5000 })
        }
    }

    useEffect(() => {
        getSiteData()
    }, [])

    const getDeviceFormData = async () => {
        const ID = location.search.split('&')
        const siteID = ID[ 0 ].replace('?site_id:', '')
        const deviceID = ID[ 1 ]?.replace('device_id:', '')
        setDeviceID(deviceID)
        setSiteID(siteID)

        if(siteID){
            const response = await API(`/api/site/id/${ siteID }`)
            setDeviceForm(response.site)
            setForm(response.site)

            response.site.floors.map((val) => val.devices.map((device) => {if(device._id === deviceID){
                setHost(device.iotHost)
                setType(device.iotType)
                setName(device.name)
                setId(device.iotId)
                setSite(response.site.name)
                setFloor(device.floor)
                hostList.map((val, i) => val.toString() === device.iotHost && setSelectHost(i))
                Type.map((val, i) => val.value === device.iotType && setSelectType(i))
                siteData && siteData.map((val, i) => {
                    if(val.name === response.site.name){
                        setSelectSite(i);
                        setFloorJson(val.floors);
                        val.floors.map((val, i) => val.name === device.floor && setSelectFloor(i))
                    }
                })
            }}))
        }else{
            setDeviceForm('')
        }
    }

    useEffect(() => {
        getDeviceFormData()
    }, [ siteData, hostList ])

    return (
        <div>
            <div className="">
                <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                    {deviceForm === '' ? t('Create_Device') : t('Edit_Device')}
                </h3>
                {!loader ?
                    <div className="bg-[#f6fbff] mt-6 w-full shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl">
                        <div className="p-6 space-y-6">
                            <div className="">
                                <h5 className='text-[#599bfb] text-lg font-medium'>{t('IoT_Host')}</h5>
                                <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                    {hostList.map((val, i) => {
                                        return (
                                            <div key={ i } onClick={ () => {setSelectHost(i); setHost(val.toString())} }>
                                                <div className={ `${ selectHost === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                    <h4 className={ `${ selectHost === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val}</h4>
                                                    {selectHost === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="">
                                <h5 className='text-[#599bfb] text-lg font-medium'>{t('IoT_Type')}</h5>
                                <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                    {Type.map((val, i) => {
                                        return (
                                            <div key={ i } onClick={ () => {setSelectType(i); setType(val.value)} }>
                                                <div className={ `${ selectType === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                    <h4 className={ `${ selectType === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.value}</h4>
                                                    {selectType === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='flex gap-[20px] sm:flex-row flex-col'>
                                <div className='w-full'>
                                    <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                        {t('Device_Name')}
                                    </label>
                                    <div className="mt-1">
                                        <input id="name" name="name" value={ deviceName } onChange={ (e) => setName(e.target.value) } type="name" autoComplete="name" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                    </div>
                                </div>
                                <div className='w-full relative'>
                                    <label htmlFor="id" className="block text-sm font-medium text-[#5a7bad]">
                                        {t('IoT_ID')}
                                    </label>
                                    <div className="mt-1">
                                        <input id="id" name='id' onChange={ (e) => setId(e.target.value) } value={ id } placeholder='' type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <h5 className='text-[#599bfb] text-lg font-medium'>{t('Site')}</h5>
                                <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                    {siteData?.map((val, i) => {
                                        return (
                                            <div key={ i } onClick={ () => handleOnchangeSiteData(val, i) }>
                                                <div className={ `${ selectSite === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                    <h4 className={ `${ selectSite === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                    {selectSite === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="">
                                <h5 className='text-[#599bfb] text-lg font-medium'>{t('Floors')}</h5>
                                <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                    {floorJson?.map((val, i) => {
                                        return (
                                            <div key={ i } onClick={ () => handleOnchangeFloorData(val, i) }>
                                                <div className={ `${ selectFloor === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                    <h4 className={ `${ selectFloor === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                    {selectFloor === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#cee9ff] px-6 py-4 mt-6 rounded-b-2xl flex justify-end">
                            <button onClick={ submitDevice } className="px-[40px] py-[10px] rounded-lg font-medium bg-[#599bfb] text-white">{t('Save')}</button></div>
                    </div> : null }
            </div>
        </div>
    )
}

export default AddDevice