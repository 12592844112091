import React, { useState } from 'react';
import { LogoLogin } from '../../Assets/AllSvg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { API } from '../../api';

const ResetPassword = () => {
    const [ email, setEmail ] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                method: 'POST',
                data : { email }
            }
            const response = await API('/resetpassword', data)
            if (response.success) {
                toast.success(response.message, { autoClose: 5000 });
                navigate('/login');
            } else {
                toast.error(response.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.error('error occurred', { autoClose: 5000 });
        }
    };

    return (
        <>
            <div className='flex justify-center items-center w-full bg-[#ddeefd]'>
                <div className='max-w-[500px] p-[20px] w-full flex flex-col justify-center items-center'>
                    <div>
                        <LogoLogin/>
                    </div>
                    <h2 className="mt-4 text-center text-3xl text-[#5a7bad] font-extrabold">
                        Innovura | Dashboard
                    </h2>
                    <p className="mt-3 text-center font-medium text-md text-[#5a7bad]">
                        Reset Your Password
                    </p>
                    <form className='w-full' onSubmit={ handleSubmit }>
                        <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-[#5a7bad]">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input id="email" name="email" type="email" autoComplete="email" required="" onChange={ (e) => setEmail(e.target.value) } className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"/>
                                </div>
                            </div>
                            <button type='submit' className="w-full mt-[6px] flex justify-center py-2 px-4 hover:border-[#599bfb] border-[2px] border-transparent rounded-full text-md font-bold hover:text-[#599bfb] text-white bg-[#599bfb] hover:transition-all transition-all hover:bg-[transparent]">
                                Send Mail
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
