import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { API } from '../../../api'

const AddFloors = () => {
    const [ form, setForm ] = useState('')
    const [ floorName, setFloorName ] = useState('')
    const [ floorFile, setFloorFile ] = useState('')
    const { t } = useTranslation()

    const navigate = useNavigate()

    const ID = location.search.replace('?id:', '')
    const getFormData = async () => {
        try {
            if(ID){
                const response = await API(`/api/site/id/${ ID }`)
                setForm(response.site)
            }else{
                setForm('')
            }
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        }
    }

    const addFloor = async () => {
        if(floorName !== ''){
            const data = {
                method: 'PATCH',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: {
                    file: form.mapImage,
                    name: form.name,
                    refresh: form.refresh,
                    floors: [ ...form.floors, { name: floorName }]
                }
            }

            const response = await API(`/api/site/update/${ form._id }`, data)
            if(response.success){
                toast.success('Floor successfully added', { autoClose: 5000 })
            }
            navigate(`/site/${ ID }`)
        }
    }

    // console.log('floorFile=======', floorFile)

    useEffect(() => {
        getFormData()
    }, [])

    return (
        <div>
            <div className="">
                <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                    {t('Create_Floor')}
                </h3>

                <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                    <div>
                        <h2 className="text-xl leading-6 font-bold text-[#599bfb]">{t('Floor_Details')}</h2>
                    </div>
                    <div className='flex gap-[20px] sm:flex-row flex-col'>
                        <div className='w-full'>
                            <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                {t('Name')}
                            </label>
                            <div className="mt-1">
                                <input onChange={ (e) => setFloorName(e.target.value) } id="name" name="name" type="name" autoComplete="name" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[20px] sm:flex-row flex-col'>
                        <div className='w-full'>
                            <label htmlFor="map" className="block text-sm font-medium text-[#5a7bad]">
                                {t('Map')}
                            </label>
                            <div className="mt-1">
                                <input type="file" onChange={ (e) => setFloorFile(e.currentTarget.files[ 0 ]) } name='map' id='map' accept="image/png, image/jpeg" />
                            </div>
                        </div>
                    </div>
                    <button onClick={ addFloor } className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>
                        {t('Save')}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default AddFloors