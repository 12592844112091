import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { BuildIcon, CopyIcon } from '../../Assets/AllSvg'
import { API } from '../../api'
import { useTranslation } from 'react-i18next'

const DeviceManagement = () => {
    const [ open, setOpen ] = useState(false)
    const [ show, setShow ] = useState(false)
    const [ selectValue, setSelectValue ] = useState( 'Select Floor' )
    const [ data, setData ] = useState('')
    const [ loader, setloading ] = useState(false)
    const [ siteData, setSiteData ] = useState([])
    const { t } = useTranslation()

    const getSiteData = async () => {
        setloading(true)
        const response = await API('/api/site/lists')
        setSiteData(response.sites)
        setloading(false)
    }

    const getDeviceCount = (val) => {
        const count = val.floors?.map((floor) => floor.devices.filter((val) => val.isDelete === false).length)
        var sum = count.reduce(function (x, y) {
            return x + y;
        }, 0);

        return sum
    }

    useEffect(() => {
        getSiteData()
    }, [])

    return (
        <>
            <div>
                <div className='flex items-center gap-4'>
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {t('Devices_Management')}
                    </h3>
                    <Link to="/device/add" className='w-fit py-[4px] px-[20px] border-[2px] bg-[#5a7bad] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_Devices')}</Link>
                    <Link to="/addsite" className='w-fit py-[4px] px-[20px] border-[2px] bg-[#599bfb] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_Sites')}</Link>
                </div>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1  mt-6 gap-4'>
                    {!loader && siteData?.map((val, i)=>{
                        return(
                            <div className='bg-[#f6fbff] w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl' key={ i }>
                                <div className='flex gap-[15px]'>
                                    <div className='bg-[#599bfb] p-4 rounded-lg'>
                                        <BuildIcon />
                                    </div>
                                    <div>
                                        <h5 className='text-[#599bfb] font-medium text-xl'>{val.name}</h5>
                                        <h5 className='text-[#5a7bad] font-medium'>{getDeviceCount(val)} {t('Devices')}</h5>
                                    </div>
                                </div>
                                <div className='flex items-center justify-end gap-4 mt-6'>
                                    <button onClick={ () => {setOpen(true); setData(val)} } className='text-[#599bfb] font-medium'>{t('Copy')}</button>
                                    <Link to={ `/device/management/${ val._id }` } className='w-fit py-[3px] px-[20px] hover:bg-[#5a7bad] bg-transparent hover:border-[#5a7bad] border-[2px] border-[#5a7bad] transition-all text-[#5a7bad] hover:text-white font-semibold rounded-md flex justify-end'>{t('Viewall')}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Transition.Root show={ open } as={ Fragment }>
                <Dialog as="div" className="relative z-10" onClose={ () => setOpen(false) }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#292929] bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                    <div>
                                        <div className='flex gap-[15px]'>
                                            <div className='bg-[#599bfb] h-fit p-3 rounded-lg'>
                                                <CopyIcon />
                                            </div>
                                            <div>
                                                <h5 className='text-[#599bfb] font-medium text-xl'>{t('Copy')} {data.title}</h5>
                                                <h5 className='text-[#5a7bad] font-normal'>{t('Copy_Site')}</h5>
                                                <div className='w-full relative mt-2'>
                                                    <label htmlFor="User Type" className="block text-sm font-medium text-[#5a7bad]">
                                                        {t('User_Type')}
                                                    </label>
                                                    <div className='relative group mt-1'>
                                                        <input defaultValue={ selectValue } onClick={ ()=>setShow(!show) } type="text" className=" block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] text-[#5a7bad] sm:text-sm" />
                                                        {show &&
                                                        <div className='absolute top-[110%] bg-white py-[5px] border-gray-300 border-[1px] rounded-lg w-full'>
                                                            <div className='py-[5px] text-sm px-[20px] text-[#5a7bad] font-medium cursor-pointer hover:bg-slate-100' onClick={ ()=>{setSelectValue('#36-JEC'); setShow(false)} }>#36-{t('JEC')}</div>
                                                            <div className='py-[5px] px-[20px] text-sm text-[#5a7bad] font-medium cursor-pointer hover:bg-slate-100' onClick={ ()=>{setSelectValue('#37-Exh'); setShow(false)} }>#37-{t('Exh')}</div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-end gap-3 mt-6'>
                                            <button onClick={ ()=>setOpen(false) } className='px-[15px] py-[5px] font-medium text-[#5a7bad] rounded-lg border-[1px] border-[#5a7bad]'>{t('Cancel')}</button>
                                            <button onClick={ ()=>setOpen(false) } className='px-[15px] py-[5px] bg-[#5a7bad] font-medium text-[#fff] rounded-lg border-[1px] border-[#5a7bad]'>{t('Copy')}</button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default DeviceManagement