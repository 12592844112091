import React, { useEffect, useState } from 'react'
import Permissions from '../../Json/permissions.json'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { API } from '../../../api'
import { useTranslation } from 'react-i18next'

const AddRole = () => {
    const [ select, setSelect ] = useState(false)
    const [ sentData, setSentData ] = useState({
        name: '',
        company: '',
        sites: '',
        permissions: []
    })
    const [ form, setForm ] = useState('')
    const navigate = useNavigate()
    const [ siteData, setSiteData ] = useState([])
    const [ users, setUser ] = useState([])
    const [ company, setCompany ] = useState([])
    const { t } = useTranslation()

    const addRole = async () => {
        if(form === ''){
            const data = {
                method: 'POST',
                data: sentData
            }
            const response = await API('/api/add/role', data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                navigate('/membermanage/roles')
            }else{
                toast.error(response.data.message.errors
                    ? response.data.message.errors.name.message
                    : response.data.message, { autoClose: 5000 }
                )
            }
        } else {
            const data = {
                method: 'PATCH',
                data: sentData
            }
            const response = await API(`/api/update/role/${ form._id }`, data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                navigate('/membermanage/roles')
            }else{
                toast.error(response.data.message.errors
                    ? response.data.message.errors.name.message
                    : response.data.message, { autoClose: 5000 }
                )
            }
        }
    }

    const addPermissions = (e) => {
        setSentData((prev) => {
            if(prev.permissions.includes(e.target.value)){
                return { ...prev, permissions: prev.permissions.filter((val) => val !== e.target.value) }
            }else {
                return { ...prev, permissions: [ ...prev.permissions, e.target.value ] }
            }
        })
    }

    const setFormData = async () => {
        const responseSite = await API('/api/site/lists')
        setSiteData(responseSite.sites)

        const userList = await API('/api/lists/member')
        const list = Object.values(userList.members).flat()
        setUser(list)

        const array = []
        list.map((val) => array.push(val.company))
        setCompany(Array.from(new Set(array)))

        try {
            const ID = location.search.replace('?id:', '')
            if(ID){
                const response = await API(`/api/list/role/${ ID }`)
                setForm(response.role)
                setSentData({
                    name: response.role.name,
                    company: response.role.company,
                    sites: response.role.sites,
                    permissions: response.role.permissions
                })
                responseSite.sites.map((val, i) => val.name === response.role.sites && setSelect(i))
            }else{
                setForm('')
            }
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        }
    }

    useEffect(() => {
        setFormData()
    }, [])

    return (
        <>
            <div>
                <div className='flex gap-2 items-center'>
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {('role_permission')}
                    </h3>
                </div>
                <div className="bg-[#f6fbff] mt-6 w-full shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl">
                    <div className="p-6 space-y-6">
                        <div className='flex w-full gap-4'>
                            <div className='w-full relative'>
                                <label htmlFor="Name" className="block text-sm font-medium text-[#5a7bad]">{t('Name')}</label>
                                <div className="mt-1">
                                    <select
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                        id="userType"
                                        name="userType"
                                        value={ sentData.name }
                                        onChange={ (e) => setSentData({ ...sentData, name: e.target.value }) }
                                    >
                                        <option value="" disabled>Select Name...</option>
                                        {users?.map((val) =>
                                            <option key={ val._id } value={ val.email }>{val.email}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor="Company" className="block text-sm font-medium text-[#5a7bad]">{t('Company')}</label>
                                <div className="mt-1">
                                    <select
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                        id="userType"
                                        name="userType"
                                        value={ sentData.company }
                                        onChange={ (e) => setSentData({ ...sentData, company: e.target.value }) }
                                    >
                                        <option value="" disabled>Select Company...</option>
                                        {company?.map((val) =>
                                            <option key={ val._id } value={ val }>{val}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <h5 className='text-[#599bfb] text-lg font-medium'>{t('Sites')}</h5>
                            <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                {siteData.map((val, i) => {
                                    return (
                                        <div key={ i } onClick={ () => {setSelect(i); setSentData({ ...sentData, sites: val.name })} }>
                                            <div className={ `${ select === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                <h4 className={ `${ select === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                {select === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <h3 className="text-sm leading-6 font-medium text-[#5a7bad] ">
                                {t('Permissions')}
                            </h3>
                            <div className='grid xl:grid-cols-3 md:grid-cols-2 mt-2 gap-4'>
                                {Permissions.map((val, i) => {
                                    return (
                                        <div className="relative flex items-start" key={ i }>
                                            <div className="flex items-center gap-3">
                                                <input
                                                    id={ `comments-${ i }` }
                                                    type="checkbox"
                                                    value={ val.name }
                                                    checked={ sentData.permissions.includes(val.name) }
                                                    onChange={ (e) => addPermissions(e) }
                                                    className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                />
                                                <div className='flex gap-2 items-center'>
                                                    <label htmlFor={ `comments-${ i }` } className="w-max whitespace-nowrap font-medium h-[21px] select-none flex items-center rounded-md bg-[#5a7bad] px-[10px] text-white text-xs">{val.badge}</label>
                                                    <label htmlFor={ `comments-${ i }` } className="select-none  font-medium text-[#5a7bad]">
                                                        {val.name}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#cee9ff] px-6 py-4 mt-6 rounded-b-2xl flex justify-end">
                        <button onClick={ addRole } className="px-[40px] py-[10px] rounded-lg font-medium bg-[#599bfb] text-white">{t('Save')}</button></div>
                </div>
            </div>
        </>
    )
}

export default AddRole