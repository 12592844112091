import React, { useState, useEffect } from 'react'
import Flatpickr from 'react-flatpickr';
import SimpleRate from '../Json/SimpleRate.json'
import 'flatpickr/dist/themes/material_green.css';
import { API } from '../../api';
import { useTranslation } from 'react-i18next';

const Download = () => {
    const [ select, setSelect ] = useState(false)
    const [ selectDevice, setSelectDevice ] = useState(false)
    const [ selectGauge, setSelectGauge ] = useState(false)
    const [ date, setDate ] = useState()
    const [ dateEnd, setDateEnd ] = useState()
    const [ devices, setDevices ] = useState([])
    const [ gauge, setGauge ] = useState([])
    const { t } = useTranslation()

    const getDevices = async () => {
        const data = []
        const sites = await API('/api/site/lists')
        sites.sites.map((val) => val.floors.map((flr) =>
            data.push(flr.devices)
        ))
        setDevices(data.flat())

        const gauges = await API('/api/lists/gauge')
        setGauge(gauges.gauge)
    }

    useEffect(() => {
        getDevices()
    }, [])

    return (
        <>
            <div>
                <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                    {t('Download_CSV')}
                </h3>
                <div className='mt-6'>
                    <div className='bg-[#f6fbff] w-full shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                        <div className='p-6'>
                            <div className='flex gap-[15px]'>
                                <div>
                                    <h5 className='text-[#599bfb] font-medium text-xl'>{t('Download_CSV')}</h5>
                                    <h5 className='text-[#5a7bad] text-md mt-1
                                font-medium'>{t('format_csv')}</h5>
                                </div>
                            </div>
                            <div className='grid lg:grid-cols-2 mt-6 gap-4'>
                                <div>
                                    <label htmlFor="Date" className="block text-sm font-medium text-[#5a7bad]">
                                        {t('Start_Date')}
                                    </label>
                                    <div className="mt-1">
                                        <Flatpickr
                                            value={ date }
                                            onChange={ ([date]) => setDate(date) }
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="Date" className="block text-sm font-medium text-[#5a7bad]">
                                        {t('End_Date')}
                                    </label>
                                    <div className="mt-1">
                                        <Flatpickr
                                            value={ dateEnd }
                                            onChange={ ([dateEnd]) => setDateEnd(dateEnd) }
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <h5 className='text-[#599bfb] text-lg font-medium'>{t('Sample_Rate')}</h5>
                                <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                    {SimpleRate.map((val, i) => {
                                        return (
                                            <div key={ i } onClick={ () => setSelect(i) }>
                                                <div className={ `${ select === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                    <h4 className={ `${ select === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.value}</h4>
                                                    {select === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="mt-6">
                                <h5 className='text-[#599bfb] text-lg font-medium'>{t('Devices')}</h5>
                                <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                    {devices.map((val, i) => {
                                        return (
                                            <div key={ i } onClick={ () => setSelectDevice(i) }>
                                                <div className={ `${ selectDevice === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                    <h4 className={ `${ selectDevice === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                    {selectDevice === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="mt-6">
                                <h5 className='text-[#599bfb] text-lg font-medium'>{t('Gauge')}</h5>
                                <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                    {gauge.map((val, i) => {
                                        return (
                                            <div key={ i } onClick={ () => setSelectGauge(i) }>
                                                <div className={ `${ selectGauge === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                    <h4 className={ `${ selectGauge === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                    {selectGauge === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#cee9ff] px-6 py-4 mt-6 rounded-b-2xl flex justify-end'>
                            <button className='px-[40px] py-[10px] rounded-lg font-medium bg-[#599bfb] text-white'>{t('Export')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Download