import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { API } from '../../../api'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const AddNotification = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const [ formData, setFormData ] = useState({
        condition : '',
        subject : '',
        message : '',
        delayFirstDetection : '',
        profile :''
    })
    const [ Profiles, setProfiles ] = useState([])
    const locationID = location.search
    const siteID = locationID.split('&')[ 0 ].replace('?site_id:', '')
    const deviceID = locationID.split('&')[ 1 ].replace('device_id:', '')

    const handleOnSubmit = async (e) => {
        e.preventDefault()

        const siteData = await API(`/api/site/id/${ siteID }`)

        const deviceList = siteData.site.floors.map((val) => val.devices).flat()
        const deviceData = deviceList.find((dev) => dev._id === deviceID)

        const payload = siteData.site.floors.map((floor) => {
            if(floor.name === deviceData.floor){
                floor.devices.map((dev) => {
                    if(dev._id === deviceData._id){
                        dev.notification = [formData]
                    }
                })
            }
            return floor
        })

        const data = {
            method: 'PATCH',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: {
                ...siteData,
                floors: payload
            }
        }
        const response = await API(`/api/site/update/${ siteID }`, data)
        if(response.success){
            toast.success('Notification added successfully', { autoClose: 5000 })
            navigate(`/device/management/${ response.site._id }`)
        }else{
            toast.error('Internal Server Error', { autoClose: 5000 })
        }
    }

    const handleOnChange = (e, field) => {
        console.log(e.target.value)
        if (field === 'delayFirstDetection') {
            setFormData ({ ...formData, [ field ] : e.target.value, delayFirstDetection : +e.target.value })
        } else {
            setFormData({ ...formData, [ field ] : e.target.value })
        }
    }

    const getMember = async ()=>{
        const response = await API('/api/lists/notification')
        // console.log(response.notification)
        setProfiles(response.notification)
    }

    const getData = async ()=>{
        const siteData = await API(`/api/site/id/${ siteID }`)
        const deviceList = siteData.site.floors.map((val) => val.devices).flat()
        const deviceData = deviceList.find((dev) => dev._id === deviceID)

        for(const floor of siteData.site.floors) {
            if(floor.name === deviceData.floor){
                floor.devices.map((dev) => {
                    if(dev._id === deviceData._id){
                        if (dev?.notification[ 0 ]){
                            const { condition, delayFirstDetection, message, profile, subject } = dev.notification[ 0 ]
                            setFormData({ condition, delayFirstDetection, message, profile, subject })
                            return dev
                        }
                    }
                })
            }
        }
    }

    useEffect(() => {
        getMember()
        getData()
    }, [])

    return (
        <div>
            <div>
                <h4 className='text-2xl leading-6 font-bold text-[#5a7bad] '>{t('Add_Notification')}</h4>
            </div>
            <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                <h4 className='text-xl leading-6 font-bold text-[#599bfb]'>{t('Add_Notification')}</h4>
                <div className='md:flex block gap-5 w-full'>
                    <div className='w-full my:my-0 my-[10px]'>
                        {/* <div>
                            <label htmlFor="" className='block text-sm font-medium text-[#5a7bad]'>{t('Notification_Condition')}</label>
                        </div>
                        <input type="text" className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-2' value={ formData.condition } onChange={ (e)=> handleOnChange(e, 'condition') }/> */}
                        <div>
                            <label htmlFor="" className='block text-sm font-medium text-[#5a7bad]'>{t('Notification_Subject')}</label>
                        </div>
                        <input type="text" className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-2' value={ formData.subject } onChange={ (e)=> handleOnChange(e, 'subject') }/>
                        <div>
                            <label htmlFor="" className='block text-sm font-medium text-[#5a7bad]'>{t('Notification_Message')}</label>
                        </div>
                        <input type="text" className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-2' value={ formData.message } onChange={ (e)=> handleOnChange(e, 'message') }/>
                        <div>
                            <label htmlFor="" className='block text-sm font-medium text-[#5a7bad]'>{t('Notification_Delay')}</label>
                            {/* Notification Delay First Detection (mins) */}
                        </div>
                        <input type="number" className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-2' value={ formData.delayFirstDetection } onChange={ (e)=> handleOnChange(e, 'delayFirstDetection') }/>
                        <div>
                            <label htmlFor="" className='block text-sm font-medium text-[#5a7bad]'>{t('Profile')}</label>
                        </div>
                        <select className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-2' value={ formData.profile } onChange={ (e)=> handleOnChange(e, 'profile') }>
                            {Profiles?.map((profile)=>{
                                return <option value={ profile._id } key={ profile._id } >{profile.name}</option>
                            })}
                        </select>

                    </div>
                </div>
                <div className='md:flex block gap-5 items-start'>
                </div>
                <div>
                    <button type='submit' onClick={ handleOnSubmit } className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>{t('Save')}</button>
                </div>
            </div>
        </div>
    )
}

export default AddNotification
