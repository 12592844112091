import axios from 'axios'
import { localStorageGet, localStorageSet } from '../utils/localstorage'
import base64 from 'base-64'

// eslint-disable-next-line no-undef
const baseURL = process.env.REACT_APP_BASE_URL
const username = 'dev_user'
const password = "Y4Kq*^=Q@PSxL8'k"

export const AuthAPI = async (url, option = {}) => {
    try {
        const newOption = {
            method: 'GET',
            ...option
        }
        const response = await axios(`${ baseURL }${ url }`, newOption)
        if (response.data.success) {
            localStorageSet('token', JSON.stringify({ jwt: response.data.token  }))
            localStorageSet('user_id', JSON.stringify(response.data.user._id))
            localStorageSet('type', response.data.user.userType)
            localStorageSet('sites', JSON.stringify(response.data.user.sites))
            localStorageSet('company', JSON.stringify(response.data.user.company))

        }

        return response.data
    } catch (error) {
        return error.response
    }
}

export const API = async (url, option = {}) => {
    try {
        const newOption = {
            method: 'GET',
            timeout:300000,
            ...option
        }
        let token = localStorageGet('token')
        token = JSON.parse(token);
        axios.defaults.headers.common[ 'Authorization' ] = token.jwt; // Set the token here

        const response = await axios(`${ baseURL }${ url }`, newOption);

        return response.data
    } catch (error) {
        return error.response
    }
}

export const HostAPI = async () => {
    try {
        const d = new Date()
        const year = d.getFullYear()
        const month = (''+(d.getMonth() + 1)).padStart(2, '0')
        const newOption = {
            method: 'POST',
            data: {
                query: 'select iot_host from "innovura-'+year+'.'+month+".*\" where iot_host <> '' group by iot_host"
            }
        }
        axios.defaults.headers.common[ 'Authorization' ] = 'Basic ' + base64.encode(username + ':' + password)

        const response = await axios(`${ baseURL }/_sql?format=json`, newOption)

        return response.data
    } catch (error) {
        return error.response
    }
}