import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API } from '../../api'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const SiteManagement = () => {
    const [ open, setOpen ] = useState(false)
    const [ siteName, setSiteName ] = useState('')
    const [ loader, setLoader ] = useState(false)
    const [ data, setData ] = useState([]);
    const navigate = useNavigate()
    const { t } = useTranslation()

    const cancelButtonRef = useRef(null)

    const getSiteData = async () => {
        setLoader(true)
        const response = await API('/api/site/lists')
        const filterData = response.sites.filter((val) => val.isDelete === false)
        setData(filterData)
        setLoader(false)
    }

    const deleteSite = async () => {
        const data = {
            method: 'PATCH',
            data:{
                floors:siteName.floors,
                name:siteName.name,
                refresh:siteName.refresh,
                isDelete:true
            }
        }
        const response = await API(`/api/site/update/${ siteName._id }`, data)

        if(response.success){
            getSiteData()
            setOpen(false)
            toast.error(response.message, { autoClose: 5000 })
        }
    }

    useEffect(() => {
        getSiteData()
    }, [])

    return (
        <>
            <div>
                <div className='flex gap-2 items-center'>
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {t('Site_Management')}
                    </h3>
                    <Link to="/addsite" className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]">
                        {t('Add_Site')}
                    </Link>
                </div>

                <div className="mt-6">
                    {loader ? null
                        : <table className='w-full divide-y divide-gray-200 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-[15px]'>
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('NAME')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('FLOORS')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('REFRESH')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">

                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {data?.map((val) =>
                                    <tr key={ val._id }>
                                        <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                            {val.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                            {val.floors.length}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                            {val.refresh}s
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-md font-semibold">
                                            <button onClick={ () => navigate(`/addsite?id:${ val._id }`) } className="text-primary hover:text-primary-focus mr-3">{t('Edit')}</button>
                                            <div className="inline" onClick={ ()=>{ setOpen(true); setSiteName(val)} }>
                                                <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                            </tbody>
                        </table>}
                </div>
            </div>
            <Transition.Root show={ open } as={ Fragment }>
                <Dialog as="div" className="relative z-10" initialFocus={ cancelButtonRef } onClose={ setOpen }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    {t('Delete')} {siteName.name}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {t('Delete_Account_des')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex justify-end sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[transparent] focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={ () => setOpen(false) }
                                            ref={ cancelButtonRef }
                                        >
                                            {t('Cancel')}
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={ deleteSite }
                                        >
                                            {t('Delete')}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default SiteManagement