/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { toast } from 'react-toastify'
import { API } from '../../../api'
import { useNavigate } from 'react-router'
import { localStorageGet } from '../../../utils/localstorage'
import { useTranslation } from 'react-i18next'

const validationSchema = yup.object({
    name: yup
        .string('Enter your name')
        .required('Name is required'),
})

const AddDashboard = () => {
    const [ siteId, setSiteId ] = useState('')
    const [ floorId, setFloorId ] = useState('')
    const [ email, setEmail ] = useState([])
    const [ device, setDevice ] = useState([])
    const[ cards, setCards ] = useState([])
    const[ image, setImage ]=useState('')
    const [ dashboardForm, setDashboardForm ] = useState('')
    const [ loading, setloading ] = useState(null)
    const [ allUser, setAllUser ] = useState([])
    const [ users, setUser ] = useState([])
    const[ companyList, setCompanyList ] = useState([])
    const [ company, setCompany ] = useState('')
    const userType = localStorageGet('type')
    const userId = localStorageGet('user_id')
    const [ imageUrl, setImageUrl ] = useState('')
    const navigate = useNavigate()
    const { t } = useTranslation()
    const URL =  process.env.REACT_APP_BASE_URL

    const addEmails = (e) => {
        setEmail((prev) => {
            if(prev.includes(e.target.value)){
                return prev.filter((val) => val !== e.target.value)
            }else {
                return [ ...prev, e.target.value ]
            }
        })
    }

    const handleChangeSelect = async(e) =>{
        setCompany(e.target.value)
        const response =  await API('/api/lists/member')
        const data = Object.values(response.members).flat().filter((val) => val.company === e.target.value)
        setUser(data)
        setAllUser(Object.values(response.members).flat())
    }

    const handleChangeSelectDevice = (e) =>{
        console.log('handleChangeSelectDevice e')
        const selectedDevice = JSON.parse(e)
        const array = []
        dashboardForm.cards.map((vals) =>{
            array.push({
                ...vals,
                groups: vals.groups.map((val) => {
                    return{
                        ...val,
                        devices: val.devices.map((value) => {
                            const val = value
                            val.iot_host = selectedDevice.iotHost
                            val.iot_type = selectedDevice.iotType
                            val.slavid_id = selectedDevice.iotId
                            val.device = selectedDevice.name
                            return { ...val }
                        }) }
                })
            })
        })
        setCards(array)
    }

    const handleUpload =(value) =>{
        const file = value.target.files[ 0 ];
        setImage(file)
        const reader = new FileReader();
        reader.onload = () => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const getSiteID = async () => {
        try{
            setloading(true)
            const ID = location.search.split('&')
            const siteID = ID[ 0 ].replace('?site_id=', '')
            const deviceID = ID[ 1 ]?.replace('floor_id=', '')
            setSiteId(siteID)
            setFloorId(deviceID)

            const res = await API('/api/lists/member')
            setCompanyList(Object.keys(res.members))

            if(ID.length === 3){
                const dashboardID = ID[ 2 ]?.replace('dashboard_id=', '')

                const response = await API(`/api/list/dashboard/${ dashboardID }`)
                const data = Object.values(res.members).flat().filter((val) => val.company === response.dashboard.company)
                const dropdownData = []
                const sites = await API('/api/site/lists')
                if(userType === 'super-admin'){
                    sites.sites.map((val) => val.floors.map((flr) =>
                        dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, device: flr.devices })
                    ))
                    setDevice(dropdownData)
                } else {
                    const sitesData = JSON.parse(localStorageGet('sites'))
                    const siteslist = sites.sites.filter((val) => sitesData.includes(val.name))

                    siteslist.map((val) => val.floors.map((flr) =>
                        dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, device: flr.devices })
                    ))
                    setDevice(dropdownData)
                }

                setUser(data)
                setAllUser(Object.values(res.members).flat())
                setDashboardForm(response.dashboard)
                setEmail(response.dashboard.email)
                setImage(response.dashboard.image)
                setCompany(response.dashboard.company)
            }
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        } finally{
            setloading(false)
        }
    }

    useEffect(() => {
        getSiteID()
        // getSiteData()
    }, [])
    return (
        <>
            <div>
                <div className="">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {dashboardForm === '' ? t('Create_Dashboard') : t('Edit_Dashboard') }
                    </h3>
                    {!loading?
                        <Formik
                            initialValues={ dashboardForm !== '' ? {
                                name: dashboardForm.name,
                                email:email,
                                company:company,
                                image:image
                            } : {
                                name: '',
                                email:'',
                                company:'',
                                image:''
                            } }
                            validationSchema={ validationSchema }
                            onSubmit={ async (values) => {
                                try {
                                    if(dashboardForm === ''){
                                        const data = {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'multipart/form-data' },
                                            data: {
                                                name: values.name,
                                                email: email,
                                                company:company,
                                                siteId: siteId,
                                                floorId: floorId,
                                                image:image
                                            }
                                        }
                                        const response = await API('/api/add/dashboard', data)
                                        if(response.success){
                                            toast.success(response.message, { autoClose: 5000 })
                                            navigate(`/site/${ siteId }`)
                                        }
                                    }else{
                                        const data = {
                                            method: 'PATCH',
                                            headers: { 'Content-Type': 'multipart/form-data' },
                                            data: {
                                                name: values.name,
                                                email: email,
                                                company:company,
                                                siteId: siteId,
                                                floorId: floorId,
                                                image: image,
                                                cards: cards
                                            }
                                        }
                                        const response = await API(`/api/update/dashboard/${ dashboardForm._id }`, data)
                                        if(response.success){
                                            toast.info(response.message, { autoClose: 5000 })
                                            navigate(`/site/${ siteId }`)
                                        }
                                    }

                                } catch (error) {
                                    toast.error('Internal Server Error', { autoClose: 5000 })
                                }
                            } }
                        >
                            {({ values,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <form onSubmit={ handleSubmit }>
                                    <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                                        <div>
                                            <h2 className="text-xl leading-6 font-bold text-[#599bfb]">{t('Dashboard_Details')}</h2>
                                        </div>
                                        <div className='flex gap-[20px] sm:flex-row flex-col'>
                                            <div className='w-full'>
                                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                                    {t('Name')}
                                                </label>
                                                <div className="mt-1">
                                                    <input id="name" name="name" value={ values.name } onChange={ handleChange } type="name" autoComplete="name" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="name"/></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-full relative'>
                                            <label htmlFor="image" className="block text-sm font-medium text-[#5a7bad]">
                                                Image Upload
                                            </label>
                                            <div className='flex items-center gap-3 w-full'>
                                                <div className="mt-1 w-full max-w-[400px]">
                                                    <input type="file" accept='image/*'className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none bg-white focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                                        onChange={ (e)=>handleUpload(e) }
                                                    />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="image" /></div>
                                                </div>
                                                <img src={ imageUrl === '' ?  URL+values.image : imageUrl } alt=""  className='w-[64px]'/>
                                            </div>
                                        </div>
                                        {dashboardForm !== '' && userType === 'super-admin' && <div>
                                            <div>
                                                <div>
                                                    <label htmlFor="" className="block text-sm font-medium text-[#5a7bad]">Device</label>
                                                </div>
                                                <select name="device"  className='h-[40px] mt-[5px] focus:shedow-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm' id="" onChange={ (e) => handleChangeSelectDevice(e.target.value) }>
                                                    <option value="" disabled selected>---</option>

                                                    {device?.map((valSite) => {
                                                        return(
                                                            <>
                                                                <option key={ valSite.id } disabled>{valSite.site}</option>
                                                                {valSite.device.map((db) =>
                                                                    <option key={ db._id } value={ JSON.stringify(db) }>{db.name}</option>
                                                                )}
                                                            </>
                                                        )}
                                                    )}
                                                </select>
                                            </div>
                                        </div>}
                                        {dashboardForm === '' && <div>
                                            <div>
                                                <div>
                                                    <label htmlFor="" className="block text-sm font-medium text-[#5a7bad]">{t('Company_Name')}</label>
                                                </div>
                                                <select name="company" value={ company } className='h-[40px] mt-[5px] focus:shedow-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm' id="company" onChange={ (e) => handleChangeSelect(e) }>
                                                    {companyList.map((val) => {
                                                        return <option key={ val._id } value={ val }>{val}</option>
                                                    })}

                                                </select>
                                            </div>
                                        </div>}
                                        {dashboardForm === '' && <div>
                                            <h3 className="text-sm leading-6 font-medium text-[#5a7bad] ">
                                                {t('Selected')}
                                            </h3>
                                            <div className='grid xl:grid-cols-3 md:grid-cols-2 mt-2 gap-4'>
                                                {allUser.map((val, i) =>
                                                    email.includes(val._id) &&
                                                    <div className="relative flex items-start" key={ i }>

                                                        <div className="flex items-center gap-3">
                                                            <input
                                                                id={ `comments-${ i }` }
                                                                type="checkbox"
                                                                value={ val._id }
                                                                onChange={ (e) => addEmails(e) }
                                                                checked={ email.includes(val._id) }
                                                                className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                            />
                                                            <div className='flex gap-2 items-center'>
                                                                <label htmlFor={ `comments-${ i }` } className="w-max whitespace-nowrap font-medium h-[21px] select-none flex items-center rounded-md bg-[#5a7bad] px-[10px] text-white text-xs">{val.company}</label>
                                                                <label htmlFor={ `comments-${ i }` } className="select-none  font-medium text-[#5a7bad]">
                                                                    {val.email}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {dashboardForm === '' && <div>
                                            <h3 className="text-sm leading-6 font-medium text-[#5a7bad] ">
                                                {t('Share_With')}
                                            </h3>
                                            <div className='grid xl:grid-cols-3 md:grid-cols-2 mt-2 gap-4'>
                                                {users.map((val, i) =>
                                                    userType === 'super-admin' ?
                                                        <div className="relative flex items-start" key={ i }>

                                                            <div className="flex items-center gap-3">
                                                                <input
                                                                    id={ `comments-${ i }` }
                                                                    type="checkbox"
                                                                    value={ val._id }
                                                                    onChange={ (e) => addEmails(e) }
                                                                    checked={ email.includes(val._id) }
                                                                    className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                                />
                                                                <div className='flex gap-2 items-center'>
                                                                    <label htmlFor={ `comments-${ i }` } className="w-max whitespace-nowrap font-medium h-[21px] select-none flex items-center rounded-md bg-[#5a7bad] px-[10px] text-white text-xs">{val.company}</label>
                                                                    <label htmlFor={ `comments-${ i }` } className="select-none  font-medium text-[#5a7bad]">
                                                                        {val.email}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : val.idOfCreator === JSON.parse(userId) &&
                                                        <div className="relative flex items-start" key={ i }>
                                                            <div className="flex items-center gap-3">
                                                                <input
                                                                    id={ `comments-${ i }` }
                                                                    type="checkbox"
                                                                    value={ val.email }
                                                                    onChange={ (e) => addEmails(e) }
                                                                    checked={ email.includes(val.email) }
                                                                    className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                                />
                                                                <div className='flex gap-2 items-center'>
                                                                    <label htmlFor={ `comments-${ i }` } className="w-max whitespace-nowrap font-medium h-[21px] select-none flex items-center rounded-md bg-[#5a7bad] px-[10px] text-white text-xs">{val.company}</label>
                                                                    <label htmlFor={ `comments-${ i }` } className="select-none  font-medium text-[#5a7bad]">
                                                                        {val.email}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )}
                                            </div>
                                        </div>}
                                        <button type='submit' className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>
                                            {t('Save')}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik> : null }
                </div>
            </div>
        </>
    )
}

export default AddDashboard