import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { API } from '../../../api'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const CreateNotification = () => {
    const [ select, setSelect ] = useState(false)
    const [ selectFloor, setSelectFloor ] = useState(false)
    const [ selectDevice, setSelectDevice ] = useState(false)
    const [ floorData, setFloorData ] = useState([]);
    const [ deviceData, setDeviceData ] = useState([]);
    const [ siteData, setSiteData ] = useState([])
    const [ notificationData, setNotificationData ] = useState('')
    const [ users, setUser ] = useState([])
    const [ form, setForm ] = useState({
        name: '',
        emails: [],
        message: '',
        condition: '',
        site: '',
        floor: '',
        device: '',
    })
    const navigate = useNavigate()
    const { t } = useTranslation()

    const addEmails = (e) => {
        setForm((prev) => {
            if(prev.emails.includes(e.target.value)){
                return { ...prev, emails: prev.emails.filter((val) => val !== e.target.value) }
            }else {
                return { ...prev, emails: [ ...prev.emails, e.target.value ] }
            }
        })
    }

    const handleOnchangeSite = (val, i) => {
        setSelect(i);
        setFloorData(val.floors);
        setDeviceData([]);
        setSelectFloor(false);
        setSelectDevice(false);
        setForm({ ...form, site: val.name })
    }

    const handleOnchangeFloor = (val, i) => {
        setSelectFloor(i);
        setDeviceData(val.devices);
        setForm({ ...form, floor: val.name })
    }

    const handleOnchangeDevice = (val, i) => {
        setSelectDevice(i)
        setForm({ ...form, device: val.name })
    }

    const addNotification = async () => {
        if(notificationData === ''){
            const data = {
                method: 'POST',
                data: { ...form, registrationDate: moment(Date()).format('YYYY-MM-DD HH:mm:ss') }
            }
            const response = await API('/api/add/notification', data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                navigate('/notifications')
            }else{
                toast.error(response.data.message.errors.name.message, { autoClose: 5000 }
                )
            }
        } else {
            const data = {
                method: 'PATCH',
                data: { ...form, registrationDate: moment(Date()).format('YYYY-MM-DD HH:mm:ss') }
            }
            const response = await API(`/api/update/notification/${ notificationData._id }`, data)
            if(response.success){
                toast.info(response.message, { autoClose: 5000 })
                navigate('/notifications')
            }else{
                toast.error(response.data.message.errors.name.message, { autoClose: 5000 })
            }
        }
    }

    const setFormData = async () => {
        const responseSite = await API('/api/site/lists')
        setSiteData(responseSite.sites)

        const userList = await API('/api/lists/member')
        setUser(Object.values(userList.members).flat())

        try {
            const ID = location.search.replace('?id:', '')
            if(ID){
                const response = await API(`/api/list/notification/${ ID }`)
                setNotificationData(response.notification)
                setForm({
                    name: response.notification.name,
                    emails: response.notification.emails,
                    message: response.notification.message,
                    condition: response.notification.condition,
                    site: response.notification.site,
                    floor: response.notification.floor,
                    device: response.notification.device,
                })

                responseSite.sites.map((val, i) => {
                    if(val.name === response.notification.site){
                        setSelect(i)
                        setFloorData(val.floors)
                        val.floors.map((flr, fI) => {
                            flr.name === response.notification.floor
                            && setSelectFloor(fI); setDeviceData(flr.devices);

                            flr.devices.map((devices, dI) => {
                                devices.name === response.notification.device
                                    && setSelectDevice(dI)
                            })
                        })
                    }
                })
            } else {
                setNotificationData('')
            }
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        }
    }

    useEffect(() => {
        setFormData()
    }, [])

    return (
        <>
            <div>
                <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                    {t('Create_Notifications')}
                </h3>
                <div className='bg-[#f6fbff] mt-6 w-full shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                    <div className="p-6 space-y-6">
                        <div>
                            <label htmlFor="Date" className="block text-sm font-medium text-[#5a7bad]">
                                {t('Name')}
                            </label>
                            <div className="mt-1">
                                <input value={ form.name } onChange={ (e) => setForm({ ...form, name: e.target.value }) } className="appearance-none block md:w-[50%] w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                            </div>
                        </div>
                        <div>
                            <h3 className="text-sm leading-6 font-medium text-[#5a7bad] ">
                                {t('Emails')}
                            </h3>
                            <div className='grid xl:grid-cols-3 md:grid-cols-2 mt-2 gap-4'>
                                {users.map((val, i) => {
                                    return (
                                        <div className="relative flex items-start" key={ i }>
                                            <div className="flex items-center gap-3">
                                                <input
                                                    id={ `comments-${ i }` }
                                                    type="checkbox"
                                                    value={ val.email }
                                                    checked={ form.emails.includes(val.email) }
                                                    onChange={ (e) => addEmails(e) }
                                                    className="h-[18px] w-[18px] rounded border-gray-400 text-[#599bfb] focus:ring-[transparent]"
                                                />
                                                <div className='flex gap-2 items-center'>
                                                    <label htmlFor={ `comments-${ i }` } className="w-max whitespace-nowrap font-medium h-[21px] select-none flex items-center rounded-md bg-[#5a7bad] px-[10px] text-white text-xs">{val.company}</label>
                                                    <label htmlFor={ `comments-${ i }` } className="select-none  font-medium text-[#5a7bad]">
                                                        {val.email}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="Message" className="block text-sm font-medium text-[#5a7bad]">
                                {t('Message')}
                            </label>
                            <textarea
                                rows={ 4 }
                                name="comment"
                                id="comment"
                                className="mt-2 appearance-none block md:w-[50%] w-full px-3 py-2 border border-gray-300 rounded-xl outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                value={ form.message }
                                onChange={ (e) => setForm({ ...form, message: e.target.value }) }
                            />
                        </div>
                        <div>
                            <label htmlFor="Condition" className="block text-sm font-medium text-[#5a7bad]">
                                {t('Condition')}
                            </label>
                            <div className="mt-1">
                                <input value={ form.condition } onChange={ (e) => setForm({ ...form, condition: e.target.value }) } className="appearance-none block md:w-[50%] w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                            </div>
                        </div>
                        <div className='flex gap-[15px]'>
                            <div>
                                <h5 className='text-[#599bfb] font-medium text-xl'>{t('Device_Selector')}</h5>
                                <h5 className='text-[#5a7bad] text-md mt-1
                                font-medium'>{t('send_mail')}</h5>
                            </div>
                        </div>
                        <div className="mt-6">
                            <h5 className='text-[#599bfb] text-lg font-medium'>Site</h5>
                            <div className='grid xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                {siteData && siteData.map((val, i) => {
                                    return (
                                        <div key={ i } onClick={ () => handleOnchangeSite(val, i) }>
                                            <div className={ `${ select === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                <h4 className={ `${ select === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                {select === i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="mt-6">
                            <h5 className='text-[#599bfb] text-lg font-medium'>Floors</h5>
                            <div className='grid xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                {floorData && floorData.map((val, i) => {
                                    return (
                                        <div key={ i } onClick={ () => handleOnchangeFloor(val, i) }>
                                            <div className={ `${ selectFloor===i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                <h4 className={ `${ selectFloor===i  ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                {selectFloor===i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="mt-6">
                            <h5 className='text-[#599bfb] text-lg font-medium'>Devices</h5>
                            <div className='grid xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                {deviceData && deviceData.map((val, i) => {
                                    return (
                                        <div key={ i } onClick={ () => handleOnchangeDevice(val, i) }>
                                            <div className={ `${ selectDevice===i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-5 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                <h4 className={ `${ selectDevice===i  ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.name}</h4>
                                                {selectDevice===i && <div className='h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='bg-[#cee9ff] px-6 py-4 mt-6 rounded-b-2xl flex justify-end'>
                        <button onClick={ addNotification } className='px-[40px] py-[10px] rounded-lg font-medium bg-[#599bfb] text-white'>{t('Save')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateNotification