import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API } from '../../../api'
import { useNavigate } from 'react-router'
import { CopyIcon } from '../../../Assets/AllSvg'
import { useTranslation } from 'react-i18next'

const ViewDevices = () => {
    const [ open, setOpen ] = useState(false)
    const [ openCopy, setOpenCopy ] = useState(false)
    const [ show, setShow ] = useState(false)
    const [ selectValue, setSelectValue ] = useState( 'Select Floor' )
    const [ copyData, setCopyData ] = useState('')
    const [ deviceData, setDeviceData ] = useState([])
    const [ site, setSite ] = useState('')
    const [ deleteData, setDeleteData ] = useState('')
    const [ loader, setloading ] = useState(false)
    const [ mqttData, setMqttData ] = useState('')
    const [ data, setData ] = useState([])
    const [ sendForm, setSendForm ] = useState('')
    const { t } = useTranslation()

    const cancelButtonRef = useRef(null)
    const navigate = useNavigate()

    const ID = location.pathname.split('/').pop()

    const getMqttData = async (iotHost)=>{
        const response = await API('/api/mqtt/'+iotHost)
        setProfiles(response.notification)
    }

    const getFormData = async () => {
        try {
            setloading(true)
            const response = await API(`/api/site/id/${ ID }`)

            const data = response.site.floors.map((val) => val.devices.filter((value) => value.isDelete === false)).flat()
            // for(const d of data){
            //     const mqtt = await getMqttData(d.iotHost)
            //     console.log(mqtt)
            //     d.mqtt = mqtt
            // }
            setDeviceData(data)
            setSite(response.site)
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        } finally{
            setloading(false)
        }
    }

    const deleteDevice = async () => {
        const floorData = site.floors.map((floor) => {
            if(deleteData.floor === floor.name){
                floor.devices = floor.devices.map((val) =>
                {
                    if(val._id === deleteData._id){
                        val.isDelete = true
                    }
                    return val
                })
            }
            return floor.devices
        })

        const data = {
            method: 'PATCH',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: site
        }
        const response = await API(`/api/site/update/${ site._id }`, data)
        if(response.success){
            toast.error('Device successfully deleted', { autoClose: 5000 })
            const floor = floorData.flat().filter((val) => val.isDelete === false)
            setDeviceData(floor)
        }
        setOpen(false)
    }

    const setFloordata = async (val) => {
        setOpenCopy(true);
        setCopyData(val)

        const response = await API('/api/site/lists')
        setData(response.sites)
    }

    const handleCopyDevice = (val, floor) => {
        setSelectValue(floor.name);
        setShow(false)

        const floorData = val.floors.map((flr) => {
            if(flr.name === floor.name){
                const data = {
                    iotHost: copyData.iotHost,
                    iotType: copyData.iotType,
                    name: copyData.name,
                    iotId: copyData.iotId,
                    floor: floor.name
                }
                flr.devices = [ ...flr.devices, data ]
            }
            return flr
        })

        setSendForm({ ...val, floors : floorData })
    }

    const onclickCopyDevice = async () => {
        try{
            setOpenCopy(false)
            const data = {
                method: 'PATCH',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: sendForm
            }
            const response = await API(`/api/site/update/${ sendForm._id }`, data)
            if(response.success){
                toast.info('Device successfully copyed', { autoClose: 5000 })
                getFormData()
            }
        }catch{
            toast.error('Internal Server Error', { autoClose: 5000 })
        }
    }

    useEffect(() => {
        getFormData()
    }, [ ID, deleteData ])

    return (
        <>
            <div>
                <div className='flex gap-2 items-center'>
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {t('Device_Management')} : {site.name}
                    </h3>
                    <Link to="/device/add" className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]">
                        {t('Add_Device')}
                    </Link>
                </div>

                <div className="mt-6">
                    {loader === false ?
                        <table className='w-full divide-y divide-gray-200 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-[15px]'>
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('SITE')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('FLOORS')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('NAME')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('IOT_HOST')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        {t('DATA')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">

                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {deviceData && deviceData.map((val) => <tr key={ val._id }>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        <div className='border-[1px] border-[#5a7bad] rounded-full text-[#5a7bad] w-max px-[10px] text-center text-[12px]'>
                                            {site.name}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                        <div className='border-[1px] border-[#599bfb] rounded-full text-[#599bfb] w-max px-[10px] text-center text-[12px]'>
                                            {val.floor}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                        {val.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                        <div>{val.iotHost}</div>
                                        <div>{val.iotType} : {val.iotId}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-900">
                                        <div>{val.mqtt}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-md font-semibold">
                                        <button onClick={ () => navigate(`/device/notification/add?site_id:${ site._id }&device_id:${ val._id }`) } className="ml-6 mr-14 inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]">
                                            {t('Add Notification')}
                                        </button>
                                        <button onClick={ () => navigate(`/device/add?site_id:${ site._id }&device_id:${ val._id }`) } className="text-primary hover:text-primary-focus mr-3">{t('Edit')}</button>
                                        <button onClick={ () => setFloordata(val) } className='text-[#599bfb] font-medium mr-3'>{t('Copy')}</button>
                                        <div className="inline">
                                            <a className="text-red-500 cursor-pointer" onClick={ ()=>{ setOpen(true); setDeleteData(val)} }>{t('Delete')}</a>
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table> : null }
                </div>
            </div>
            <Transition.Root show={ open } as={ Fragment }>
                <Dialog as="div" className="relative z-10" initialFocus={ cancelButtonRef } onClose={ setOpen }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    {t('Delete')} {deleteData.name}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {t('Delete_Account_des')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex justify-end sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[transparent] focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            ref={ cancelButtonRef }
                                            onClick={ () => setOpen(false) }
                                        >
                                            {t('Cancel')}
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={ deleteDevice }
                                        >
                                            {t('Delete')}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={ openCopy } as={ Fragment }>
                <Dialog as="div" className="relative z-10" onClose={ () => setOpenCopy(false) }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#292929] bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                    <div>
                                        <div className='flex gap-[15px]'>
                                            <div className='bg-[#599bfb] h-fit p-3 rounded-lg'>
                                                <CopyIcon />
                                            </div>
                                            <div>
                                                <h5 className='text-[#599bfb] font-medium text-xl'>{t('Copy')} {copyData.name}</h5>
                                                <h5 className='text-[#5a7bad] font-normal'>{t('Copy_Site')}</h5>
                                                <div className='w-full relative mt-2'>
                                                    <label htmlFor="User Type" className="block text-sm font-medium text-[#5a7bad]">
                                                        {t('User_Type')}
                                                    </label>
                                                    <div className='relative group mt-1'>
                                                        <input value={ selectValue } onClick={ ()=>setShow(!show) } type="text" className=" block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] text-[#5a7bad] sm:text-sm" />
                                                        {show &&
                                                        <div className='absolute top-[110%] bg-white py-[5px] border-gray-300 border-[1px] rounded-lg w-full'>
                                                            {data && data.map((val, i) => <div key={ i }>
                                                                <div className='py-[5px] font-bold text-sm px-[20px] text-[#599bfb] font-medium cursor-pointer hover:bg-slate-100'># {val.name}</div>
                                                                {val.floors?.map((floor) =>
                                                                    <div key={ floor._id } onClick={ ()=> handleCopyDevice(val, floor) } className='py-[5px] px-[20px] pl-[35px] text-sm text-[#5a7bad] font-medium cursor-pointer hover:bg-slate-100'>{floor.name}</div>
                                                                )}
                                                            </div>
                                                            )}
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-end gap-3 mt-6'>
                                            <button onClick={ ()=>setOpenCopy(false) } className='px-[15px] py-[5px] font-medium text-[#5a7bad] rounded-lg border-[1px] border-[#5a7bad]'>{t('Cancel')}</button>
                                            <button onClick={ onclickCopyDevice } className='px-[15px] py-[5px] bg-[#5a7bad] font-medium text-[#fff] rounded-lg border-[1px] border-[#5a7bad]'>{t('Copy')}</button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default ViewDevices