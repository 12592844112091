import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify'
import { IoMdRemoveCircle } from 'react-icons/io'
import { API } from '../../../api';
import { useTranslation } from 'react-i18next';

const CreateProfile = () => {
    const navigate = useNavigate()
    const location =  useLocation()
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const companyName = params.get('name')
    const companyId = params.get('_id')
    const [ form, setForm ] = useState({
        name:'',
        company_name:companyName,
        company_id:companyId,
        sms:[],
        whatsapp:[],
        email:[]
    })
    const { t } = useTranslation()

    const addProfile = async() =>{
        const message =[]
        message.push(form.sms)
        message.push(form.email)
        message.push(form.whatsapp)
        try{
            const data = {
                method: 'POST',
                data: {
                    name:form.name,
                    company_name: companyName,
                    company_id: companyId,
                    sms:form.sms.map((val) => val),
                    whatsapp:form.whatsapp.map((val) => val),
                    email:form.email.map((val) => val)
                }
            }
            const response =  await API('/api/add/notification', data)
            if(response.success){
                toast.success('Profile successfully added', { autoClose: 5000 })
                navigate('/notifications')
            }
        }catch(error){
            toast.error(error, { autoClose: 5000 })
        }
    }

    const handleWhatsappOnChange = (e, id) =>{
        const whatsapps = [...form.whatsapp]
        whatsapps[ id ].value = e;
        setForm({ ...form, whatsapp:whatsapps })
    }
    const handleRemoveWhatsappFields = (index) => {
        const value  = [...form.whatsapp];
        value.splice(index, 1);
        setForm({ ...form, whatsapp:value });
    }
    const handleEmailOnChange = (e, id) =>{
        const emails = [...form.email]
        emails[ id ].value = e;
        setForm({ ...form, email:emails })
    }
    const handleRemoveEmailFields = (index) => {
        const value  = [...form.email];
        value.splice(index, 1);
        setForm({ ...form, email:value });
    }
    const handleSmsOnChange = (e, id) =>{
        const sms = [...form.sms]
        sms[ id ].value = e;
        setForm({ ...form, sms:sms })
    }
    const handleRemoveSmsFields = (index) => {
        const value  = [...form.sms];
        value.splice(index, 1);
        setForm({ ...form, sms:value });
    }

    return (
        <div>
            <div>
                <h4 className='text-2xl leading-6 font-bold text-[#5a7bad] '>{t('Create_Profile')}</h4>
            </div>
            <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                <h4 className='text-xl leading-6 font-bold text-[#599bfb]'>{t('Profile_Detalis')}</h4>
                <div className='md:flex block gap-5 w-full'>
                    <div className='w-full my:my-0 my-[10px]'>
                        <div>
                            <label htmlFor="" className='block text-sm font-medium text-[#5a7bad]'>{t('Name')}</label>
                        </div>
                        <input type="text"  value={ form.name }className='appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-2' onChange={ (e) => setForm({ ...form, name: e.target.value }) }/>
                    </div>
                </div>
                <div className='md:flex block gap-5 items-start'>
                    <div className='w-full md:my-0 my-[10px] shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] p-[20px]'>
                        <button className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white px-[10px] flex justify-center mb-4'
                            onClick={ () => setForm( { ...form, whatsapp:[ ...form.whatsapp, { value:'' }] }) }
                        >
                            {t('Whatsapp')} + </button>
                        {form.whatsapp.map((val, i) =>
                            <div key={ i } className='flex items-center gap-3'>
                                <input type="text" value={ val.value } className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-[10px]'
                                    onChange={ (e) => handleWhatsappOnChange(e.target.value, i) }/>
                                <button className='text-[20px]' onClick={ () =>handleRemoveWhatsappFields(i) }>
                                    <IoMdRemoveCircle className='text-[#599bfb]' />
                                </button>
                            </div>
                        )}

                    </div>
                    <div className='w-full md:my-0 my-[10px] shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] p-[20px]'>
                        <button
                            onClick={ () => setForm( { ...form, email:[ ...form.email, { value:'' }] }) }
                            className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white px-[10px] flex justify-center mb-4'>{t('Email')} + </button>
                        {form.email.map((val, i) =>
                            <div key={ i } className='flex items-center gap-3'>
                                <input type="text" value={ val.value } className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-[10px]'
                                    onChange={ (e) => handleEmailOnChange(e.target.value, i) }/>
                                <button className='text-[20px]' onClick={ () => handleRemoveEmailFields(i) }>
                                    <IoMdRemoveCircle className='text-[#599bfb]' />
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='w-full md:my-0 my-[10px] shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] p-[20px]'>
                        <button
                            onClick={ () => setForm( { ...form, sms:[ ...form.sms, { value:'' }] }) }
                            className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white px-[10px] flex justify-center mb-4'>{t('SMS')} + </button>
                        {form.sms.map((val, i) =>
                            <div key={ i } className='flex items-center gap-3'>
                                <input type="text" value={ val.value } className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm mt-[10px]'
                                    onChange={ (e) => handleSmsOnChange(e.target.value, i) }/>
                                <button className='text-[20px]' onClick={ () => handleRemoveSmsFields(i) }>
                                    <IoMdRemoveCircle className='text-[#599bfb]' />
                                </button>
                            </div>
                        )}

                    </div>
                </div>
                <div>
                    <button type='submit' className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center' onClick={ () => addProfile(form) }>{t('Save')}</button>
                </div>
            </div>
        </div>
    )
}

export default CreateProfile
