/* eslint-disable camelcase */
export const EngWord =
    {
        Last_updated: 'Last updated',
        Dashboard : 'Dashboard',
        Category:'Category',
        Reports : 'Reports',
        Devices_Management : 'Device Management',
        Viewall : 'View all',
        Average:'Average',
        Maximum:'Maximum',
        Minimum:'Minimum',
        Profile : 'Profile',
        Personal_Details :'Personal Details',
        Manage_Information : 'Manage how information is displayed on your account.',
        Email_address:'Email address',
        Company:'Company',
        Registration_Date : 'Registration Date',
        Password:'Password',
        Confirm_Password :'Confirm Password',
        Confirm:'Confirm',
        Save : 'Save',
        Download:'Download',
        Download_CSV :'Download CSV',
        format_csv:'format .csv',
        Start_Date :'Start Date',
        End_Date :'End Date',
        Sample_Rate:'Sample Rate',
        Devices:'Devices',
        Gauge:'Gauge',
        Export:'Export',
        Notifications:'Notifications',
        New_Profile : 'New Profile',
        Company_Name:'Company Name',
        Cancel : 'Cancel',
        Create_Profile :'Create Profile',
        Edit_Profile:'Edit Profile',
        Profile_Detalis:'Profile Detalis',
        Name:'Name',
        Whatsapp:'Whatsapp',
        Email:'Email',
        SMS:'SMS',
        Edit:'Edit',
        Delete:'Delete',
        Delete_Notificaiton_Description:'Are you sure you want to delete the profile? All of your data will be permanently removed from our servers forever. This action cannot be undone.',
        Create_Device:'Create Device',
        Edit_Device:'Edit Device',
        IoT_Host:'IoT Host',
        IoT_Type:'IoT Type',
        Device_Name:'Device Name',
        IoT_ID:'IoT ID',
        Site:'Site',
        Floors:'Floors',
        Copy:'Copy',
        DATA:'DATA',
        Copy_Site:'Are you sure you want to copy this site?',
        User_Type:'User Type',
        Select_Floor:'Select Floor',
        Site_Details:'Site Details',
        Refresh_site:'Refresh (Second)',
        Map:'Map',
        Add_Floor:'Add Floor',
        Create_Floor:'Create Floor',
        Floor_Details:'Floor Details',
        Add_Site:'Add Site',
        Site_Management:'Site Management',
        REFRESH:'REFRESH',
        AutoREFRESH:'Refresh',
        Member_Management:'Member Management',
        Add_Member:'Add Member',
        ROLE:'ROLE',
        STATUS:'STATUS',
        Create_Member:'Create Member',
        Sites:'Sites',
        Permissions:'Permissions',
        System_Log:'System Log',
        IP_and_Meter_Settings:'IP and Meter Settings',
        Generate_Reports:'Reports',
        IpAddress:'IP Address',
        QOT_MQTT:'MQTT Status (Live)',
        SECONDS:'Sec.',
        MINUTES:'Min.',
        DATALOGGER:'Data Logger',
        QOTLOGGER:'QOT Logger',
        QOTLOGGERHISTORY:'QOT Logger History',
        TIMESTAMP:'TIMESTAMP',
        TYPE:'TYPE',
        MESSAGE:'MESSAGE',
        ENV:'ENV',
        View:'View',
        SITE:'SITE',
        Search:'Search',
        Logout:'Logout',
        Settings:'Settings',
        Select_Chart_Type:'Select Chart Type',
        Card_Type:'Card Type',
        Card_Width:'Card Width',
        Card_title:'Card title',
        Unit_Default:'Unit (Default)',
        Order:'Order',
        Min:'Min Value (Display)',
        Max:'Max Value (Display)',
        Group_As:'Group As',
        ShowLatest:'Show Latest',
        ShowDailyHours:'Show daily usage hours',
        Mode:'Mode',
        Group_Name:'Group Name',
        ITEM:'ITEM',
        ORDER_KEY:'ORDER KEY',
        GAUGE:'GAUGE',
        Add_Device:'Add Device',
        DEVICE:'DEVICE',
        Notification_Condition:'Notification Condition',
        Notification_Subject:'Notification Subject',
        Notification_Message:'Notification Message',
        Notification_Delay:'Notification Delay first detection (mins)',
        Evaluation:'Evaluation',
        Unit:'Unit',
        Display_Type:'Display Type',
        Base_value:'Base Value',
        Max_diff:'Get Max Min Difference',
        accumulative:'Reading is accumulative',
        Remove_value:'Remove zero values',
        From_Date:'From Date',
        To_Date:'To Date',
        default_line_color:'Default line color',
        Add_Device_Group:'Add Device Group',
        Update_Card:'Update Card',
        Download_All_CSV:'Download All CSV',
        back:'Back',
        SelectCards:'Select Cards',
        UnSelectCards:'Unselect Cards',
        Create_Dashboard:'Create Dashboard',
        Edit_Dashboard:'Edit Dashboard',
        Dashboard_Details:'Dashboard Details',
        Share_With:'Share With',
        Add_new_Dashboard:'Add new dashboard',
        Delete_dashboard:'Delete dashboard',
        Edit_dashboard:'Edit dashboard',
        Copy_dashboard:'Copy dashboard',
        COMPANY:'COMPANY',
        EMAIL:'EMAIL',
        FLOORS:'FLOORS',
        NAME:'NAME',
        CustomCard_width:'Custom Card Width (vw)',
        CustomCard_height:'Custom Card Height (vh)',
        Group:'Group',
        true_text:'when true text',
        false_text:'when false text',
        true_color:'When true color',
        line_color:'Line color',
        false_color:'When false color',
        true_text_color:'when true text color',
        false_text_color:'when false text color',
        New_card:'New Card',
        label:'label',
        Add_Card:'Add Card',
        edit:'edit',
        Edit_Chart:'Edit Chart',
        copy_word:'Are you sure you want to copy this chart?',
        qotSelect:'Please select the dashboard for this QOT.',
        Select:'Select',
        From:'From',
        To:'To',
        IOT_HOST:'IOT HOST',
        Delete_Account_des:'Are you sure you want to delete your account? All of your data will be permanently removed from our servers forever. This action cannot be undone.',
        Add_Devices:'Add Devices',
        Add_Sites:'Add Sites',
        JEC:'JEC',
        Exh:'Exh',
        Add_device:'Add device',
        delete_dashboard_des:'Are you sure you want to delete dashboard? All of your data will be permanently removed from our servers forever. This action cannot be undone.',
        copy_dashbord:'Are you sure you want to copy this dashboard?',
        Floor:'Floor',
        role_permission:'Role & Permission Management',
        All_permissions:'All permissions',
        Add_Role:'Add Role',
        PERMISSION:'PERMISSION',
        Action:'Action',
        Create_Notifications:'Create Notifications',
        Emails:'Emails',
        Message:'Message',
        Condition:'Condition',
        Device_Selector:'Device Selector',
        send_mail:'Send to selected email addresses',
        Demo_Report:'Demo Report',
        Report:'Report',
        Admin_Only:'Admin Only',
        Select_Language:'Select Language',
        Device_Management:'Device Management',
        Create_Site:'Create Site',
        New_Card:'New Card',
        Card_Height:'Card Height',
        Device_Cards :'Device Cards',
        Add_Notification:'Add Notification',
        Selected:'Selected'
    }