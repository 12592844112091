import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './App.css'
import PageRoutes from './routes'

function App() {
    return (
        <Router>
            <PageRoutes />
            <ToastContainer />
        </Router>
    )
}

export default App
