import React, { useContext,  useState } from 'react'
import * as yup from 'yup'
import { ErrorMessage,  Formik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API, AuthAPI } from '../../api'
import { LogoLogin } from '../../Assets/AllSvg'
import AuthContext from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import BarLoader from 'react-spinners/BarLoader';

const loginValidationSchema = yup.object({
    email: yup.string('Enter Your Email')
        .required('Email is required')
        .test('is-email', 'Invalid email', (value) => {
            if (value) {
                return value.includes('@') ? yup.string().email().isValidSync(value) : true
            }
            return true
        }),
    password: yup
        .string('Enter your password')
        .min(6, 'Password must be at least 6')
        .required('Password is required'),
})

const Login = () => {
    const navigate = useNavigate()
    const auth = useContext(AuthContext)
    const [ showPassword, setShowPassword ] = useState(false);
    const [ loading, setLoading ] = useState(false)
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <>
            <div className='flex justify-center items-center h-screen w-full bg-[#ddeefd]'>

                <div className='max-w-[500px] p-[20px] w-full flex flex-col justify-center items-center'>
                    <div>
                        <LogoLogin/>
                    </div>
                    <h2 className="mt-4 text-center text-3xl text-[#5a7bad] font-extrabold">
                        Innovura | Dashboard
                    </h2>
                    <p className="mt-3 text-center font-medium text-md text-[#5a7bad]">
                        Sign in to your account
                    </p>
                    <Formik
                        initialValues={ {
                            email: '',
                            password: '',
                        } }
                        validationSchema={ loginValidationSchema }
                        onSubmit={ async (values) => {
                            try {
                                setLoading(true)
                                const data = {
                                    method: 'POST',
                                    data: {
                                        ...values
                                    }
                                }
                                const response = await AuthAPI('/login', data)
                                if (response.success) {
                                    auth.login()
                                    const res =await  API('/api/lists/dashboard')
                                    const siteId = response.user.sites
                                    const dashboard = res.dashboard.filter((val) =>  val.siteId.includes(siteId))
                                    if(response.user.sites.length === 1 && dashboard.length === 1){
                                        navigate('/dashboard')
                                        toast.success('Sign in successfully', { autoClose: 5000 })
                                        setLoading(false)
                                    }else if(response.user.sites.length === 1 && dashboard.length >= 1){
                                        navigate(`/site/${ siteId }`)
                                        setLoading(false)
                                    }else{
                                        navigate('/dashboard')
                                        setLoading(false)
                                    }
                                } else {
                                    toast.error( response.data.message.errors, { autoClose: 5000 })
                                    setLoading(false)
                                }
                            } catch (error) {
                                setLoading(false)
                                toast.error('An error occurred, please try again.', { autoClose: 5000 })
                            }
                        } }
                    >
                        {({ values,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form className='w-full' onSubmit={ handleSubmit }>
                                <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-[#5a7bad]">
                                            Email address
                                        </label>
                                        <div className="mt-1">
                                            <input id="email" name="email" type="email" autoComplete="email" required="" value={ values.email } onChange={ handleChange } className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"/>
                                        </div>
                                        <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="email"/></div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="Password" className="block text-sm font-medium text-[#5a7bad]">
                                            Password
                                        </label>
                                        {/* <div className="mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                required=""
                                                value={ values.password }
                                                onChange={ handleChange }
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"/>
                                        </div> */}

                                        <div className="mb-2 flex items-center mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type={ showPassword ? 'text' : 'password' }
                                                required=""
                                                value={ values.password }
                                                onChange={ handleChange }
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"/>
                                            <FontAwesomeIcon
                                                icon={ showPassword ? faEyeSlash : faEye }
                                                onClick={ togglePasswordVisibility }
                                                className='ml-3 relative text-gray-400 cursor-pointer float-right'
                                            />
                                        </div>

                                        <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="password"/></div>
                                    </div>
                                    {!loading && <button type='submit' autoFocus className="w-full mt-[6px] flex justify-center py-2 px-4 hover:border-[#599bfb] border-[2px] border-transparent rounded-full text-md font-bold hover:text-[#599bfb] text-white bg-[#599bfb] hover:transition-all transition-all hover:bg-[transparent]">
                                        Sign in
                                    </button>}

                                    <div className="items-stretch">
                                        <div className="text-md font-medium gap-2 flex text-[#5a7bad]">
                                            <button onClick = { () => navigate('/resetpassword') } className="font-medium text-[#599bfb]">Forgot Password</button>
                                        </div>
                                        <div className="text-md font-medium gap-2 flex text-[#5a7bad]">
                                            Have a question?
                                            <Link to='https://www.energy-vergence.com/' className="font-medium text-[#599bfb]">
                                                Contact us!
                                            </Link>
                                        </div>
                                    </div>
                                    {loading && <div className="flex justify-center items-center">
                                        <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
                                    </div>}
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default Login