import { toast } from 'react-toastify'
import { API } from '../../api'
import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';

const DeviceCard = ({ id, dashboardId, refresh, toDate, fromDate }) => {

    const [ loading, setLoading ] = useState(true)
    const [ data, setData ] = useState([])

    const getChartData = async (loading) => {
        setLoading(loading)
        try{
            const data = {
                method: 'POST',
                data : {
                    _id : dashboardId,
                    cardId : id,
                    fromDate,
                    toDate
                }
            }
            const chartAPI = await API('/lightdata', data)
            setLoading(false)
            // console.log('device chart * data', chartAPI.chartData.data)
            setData(chartAPI.chartData.data)
        }catch (error) {
            setLoading(false)
            toast.error(error, { autoClose: 5000 })
        }

    }

    useEffect(() => {
        if ((fromDate !== '' && toDate !== '') || (fromDate == '' && toDate == '')){
            getChartData(true)
            const interval = setInterval(() => {
                if(refresh){
                    getChartData(false)
                }
            }, 10000)
            return () => clearInterval(interval);
        }
    }, [ toDate, fromDate ])

    const setFields = () => {
        const fields = []
        {data?.map((items)=>{
            if (typeof items === Array){
                {items?.map((item)=>{
                    fields.push(
                        <span className='text-normal text-black font-bold text-center p-2'>{parseFloat(item?.buckets?.y).toFixed(1)} {item?.unit}<br></br><span className='text-[#5a7bad]'>{items?.label}</span></span>
                    )
                }) }
            }else{
                fields.push(
                    <p className='text-normal text-black font-bold text-center p-2'>{parseFloat(items?.buckets?.y).toFixed(1)} {items?.unit}<br></br><span className='text-[#5a7bad]'>{items?.label}</span></p>
                )
            }

        }) }
        return fields
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center">
                <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
            </div>
        )
    }
    else
        return <div className='text-center mb-3 flex justify-center Office-card'>
            {setFields()}
        </div>
}

export default DeviceCard