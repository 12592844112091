/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { toast } from 'react-toastify'
import jsonData from '../../Json/CardJson.json'
import GroupList from './GroupList'
import { API } from '../../../api'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const validationSchema = yup.object({
    name: yup
        .string('Enter your Title')
        .required('Title is required'),
    unit: yup
        .string('Enter Unit(Default)')
        .required('Unit is required'),
    order: yup
        .string('Enter your order')
        .required('Order is required'),
    min: yup
        .string('Enter your min value')
        .required('Min value is required'),
    max: yup
        .string('Enter your max value')
        .required('Max value is required'),
})

const AddCard = () => {
    const [ select1, setSelect1 ] = useState(false)
    const [ select2, setSelect2 ] = useState(false)
    const [ select3, setSelect3 ] = useState(false)
    const [ select4, setSelect4 ] = useState(false)
    const [ select5, setSelect5 ] = useState(false)
    const [ custom, setCustom ] = useState(false)
    const [ heightCustom, setHeightCustom ] = useState(false)
    const [ dboardId, setDboardId ] = useState('')
    const [ dboardData, setDboardData ] = useState('')
    const [ formData, setFormData ] = useState('')
    const [ loading, setloading ] = useState(null)
    // const [ width, setCardWidth ] = useState('')
    const [ chartData, setChartData ] = useState({
        type: '',
        width: '',
        height:'',
        base_value: '',
        widthType: '',
        heightType: '',
        group : '',
        mode: '',
    })
    const { t } = useTranslation()
    const [ groupArray, setGroupArray ] = useState([{
        label : '',
        devices : [],
        evaluation : '',
        notification_condition:'',
        notification_subject: '',
        profile:'',
        notification_message:'',
        notification_delay:'',
        unit : '',
        type : 'Numeric',
        backgroudColor : '',
        default_line_color : '',
        isDiff: false,
        accumulative: false,
        fromDate: '',
        toDate: '',
        trueText: '',
        falseText: '',
        trueColor: '',
        falseColor: '',
        textTrueColor: '',
        textfalseColor: '',
        removeZeroValues: false,
        notificationDisplay:false
    }])

    const navigate = useNavigate()

    const handleOnclickGroup = () => {
        setGroupArray([ ...groupArray, {
            label : '',
            devices : [],
            evaluation : '',
            notification_condition:'',
            notification_subject: '',
            profile:'',
            notification_message:'',
            notification_delay:'',
            unit : '',
            type : 'Numeric',
            backgroudColor : '',
            default_line_color : '',
            isDiff: false,
            accumulative: false,
            fromDate: '',
            toDate: '',
            trueText: '',
            falseText: '',
            trueColor: '',
            falseColor: '',
            textTrueColor: '',
            textfalseColor: '',
            removeZeroValues: false,
            notificationDisplay:false

        }])
    }

    const getIDs = async () => {
        try{
            setloading(true)
            const ID = location.search.split('&')
            const dashboardID = ID[ 0 ].replace('?id=', '')
            setDboardId(dashboardID)

            const response = await API(`/api/list/dashboard/${ dashboardID }`)
            setDboardData(response.dashboard)

            if(ID[ 1 ] !== ''){
                const cardID = ID[ 1 ].replace('card_id=', '')
                const cardData = response.dashboard.cards.find((val) => val._id === cardID)
                setFormData(cardData)
                console.log(cardData.groups)
                setGroupArray(cardData.groups)
                console.log(cardData)
                setChartData({
                    type: cardData.type,
                    width: cardData.width,
                    height: cardData.height,
                    widthType: cardData.widthType,
                    heightType: cardData.heightType,
                    group : cardData.groupAs,
                    base_value : cardData?.base_value,
                    mode: cardData.mode,
                })
                if (cardData.widthType === 'custom'){
                    setCustom(true)
                    setSelect2(7)
                }
                else{
                    jsonData.width.map((val, i) => val.value === cardData.width && setSelect2(i))
                }
                if (cardData.heightType === 'custom'){
                    setHeightCustom(true)
                    setSelect5(7)
                }
                else{
                    jsonData.height.map((val, i) => val.value === cardData.height && setSelect5(i))
                }
                jsonData.charts.map((val, i) => val.value === cardData.type && setSelect1(i))
                jsonData.charts.map((val, i) => val.value === cardData.height && setSelect5(i))
                jsonData.group.map((val, i) => val.esVal === cardData.groupAs && setSelect3(i))
                jsonData.mode.map((val, i) => val.value === cardData.mode && setSelect4(i))
            }
        } catch (error) {
            toast.error(error, { autoClose: 5000 })
        } finally{
            setloading(false)
        }
    }

    useEffect(() => {
        getIDs()
    }, [])

    return (
        <div>
            <div className="">
                <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                    {formData !== '' ? t('Update_Card') : t('New_Card') }
                </h3>
                {!loading ?
                    <Formik
                        initialValues={ formData !== '' ? {
                            name: formData.title,
                            unit: formData.unit,
                            order: formData.order,
                            min: formData.minValue,
                            base_value: formData.base_value,
                            max: formData.maxValue,
                        } : {
                            name: '',
                            unit: '',
                            order: '',
                            base_value: '',
                            min: '',
                            max: '',
                        } }
                        validationSchema={ validationSchema }
                        onSubmit={ async (values) => {
                            try {
                                const finalGroups = groupArray.map((val) =>{
                                    val.devices.map((dev) =>
                                    {
                                        dev.iot_host,
                                        dev.iot_type,
                                        dev.slavid_id,
                                        dev.evaluation = val.evaluation,
                                        dev.notification_condition = val.notification_condition,
                                        dev.notification_subject = val.notification_subject,
                                        dev.profile = val.profile,
                                        dev.notification_message = val.notification_message,
                                        dev.notification_delay = val.notification_delay,
                                        dev.fixed_interval = chartData.group
                                        dev.field = 'time',
                                        dev.avg_field = dev.gauge
                                    })
                                    return val
                                })

                                const data = {
                                    type : chartData.type,
                                    width : chartData.width,
                                    height:chartData.height,
                                    widthType : chartData.widthType,
                                    heightType : chartData.heightType,
                                    title : values.name,
                                    unit : values.unit,
                                    order : values.order,
                                    minValue : values.min,
                                    maxValue : values.max,
                                    base_value : values.base_value,
                                    groupAs : chartData.group,
                                    mode : chartData.mode,
                                    groups : finalGroups,
                                    isDelete:false
                                }

                                console.log(data)

                                if(chartData.type !== '' && chartData.width !== '' && chartData.group !== '' && chartData.mode !== ''){
                                    if(formData === ''){
                                        // console.log('finalData')
                                        // console.log(dboardData)
                                        const finalData = {
                                            method: 'PATCH',
                                            data : {
                                                ...dboardData,
                                                cards: [ ...dboardData.cards, data ]
                                            }
                                        }
                                        const response = await API(`/api/update/dashboard/${ dboardId }`, finalData)
                                        if(response.success){
                                            toast.success('Card successfully added', { autoClose: 5000 })
                                            navigate(`/dashboard/card?dashboard_id=${ dboardId }`)
                                        }
                                    } else{
                                        const updateData = dboardData.cards.map((val) => {
                                            if(val._id === formData._id){
                                                val.type = chartData.type,
                                                val.width = chartData.width,
                                                val.height=chartData.height,
                                                val.widthType = chartData.widthType,
                                                val.heightType = chartData.heightType,
                                                val.title = values.name,
                                                val.unit = values.unit,
                                                val.order = values.order,
                                                val.minValue = values.min,
                                                val.maxValue = values.max,
                                                val.base_value = values.base_value,
                                                val.groupAs = chartData.group,
                                                val.mode = chartData.mode,
                                                val.groups = finalGroups
                                            }
                                            return val
                                        })
                                        // console.log('updateData')
                                        // console.log(updateData)
                                        const finalData = {
                                            method: 'PATCH',
                                            data : {
                                                ...dboardData,
                                                cards: updateData
                                            }
                                        }
                                        const response = await API(`/api/update/dashboard/${ dboardId }`, finalData)
                                        if(response.success){
                                            toast.info('Card successfully updated', { autoClose: 5000 })
                                            navigate(`/dashboard/card?dashboard_id=${ dboardId }`)
                                        }
                                    }

                                } else {
                                    toast.error('Card type, width, group as and mode is required', { autoClose: 5000 })
                                }

                            } catch (error) {
                                toast.error('Internal Server Error', { autoClose: 5000 })
                            }
                        } }
                    >
                        {({ values,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={ handleSubmit }>
                                <div className='bg-[#f6fbff] mt-6 w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                                    <div>
                                        <h2 className="text-xl leading-6 font-bold text-[#599bfb]">{t('Select_Chart_Type')}</h2>
                                    </div>
                                    <div className="">
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                            {t('Card_Type')}
                                        </label>
                                        <div className='grid xl:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                            {jsonData && jsonData.charts.map((val, i) => {
                                                return (
                                                    <div key={ i } onClick={ () => {setSelect1(i); setChartData({ ...chartData, type: val.value })} }>
                                                        <div className={ `${ select1 === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-2 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                            <h4 className={ `${ select1 === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.value}</h4>
                                                            {select1 === i && <div className='mr-1 h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                            {t('Card_Width')}
                                        </label>
                                        <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                            {jsonData && jsonData.width.map((val, i) => {
                                                return (
                                                    <div
                                                        key={ i }
                                                        onClick={ () => {
                                                            setSelect2(i);
                                                            if(val.value === 'Custom'){
                                                                setCustom(true)
                                                                // setCardWidth('')
                                                            }
                                                            else{
                                                                setCustom(false)
                                                                // setCardWidth('')
                                                                setChartData({ ...chartData, width: val.value, widthType: '' })
                                                            }
                                                        }
                                                        }>
                                                        <div className={ `${ select2 === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-2 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                            <h4 className={ `${ select2 === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.value}</h4>
                                                            {select2 === i && !custom && <div className='mr-1 h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                            {custom && (select2 === i) && <div className='mr-1 h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {custom && <div className='w-full mt-2'>
                                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                                    {t('CustomCard_width')}
                                                </label>
                                                <div className="mt-1">
                                                    <input id="name" name="name" type="text" value={ parseFloat(chartData.width) } onChange={ (e)=>{
                                                        // setCardWidth(e.target.value);
                                                        setChartData({ ...chartData, width: e.target.value, widthType: 'custom' } ) } } autoComplete="name" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="name"/></div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                            {t('Card_Height')}
                                        </label>
                                        <div className='grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                            {jsonData && jsonData.height.map((val, i) => {
                                                return (
                                                    <div
                                                        key={ i }
                                                        onClick={ () => {
                                                            setSelect5(i);
                                                            if(val.value === 'Custom'){
                                                                setHeightCustom(true)
                                                            }
                                                            else{
                                                                setHeightCustom(false)
                                                                setChartData({ ...chartData, height: val.value })
                                                            }
                                                        }
                                                        }>
                                                        <div className={ `${ select5 === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-2 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                            <h4 className={ `${ select5 === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.value}</h4>
                                                            {select5 === i && !custom && <div className='mr-1 h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                            {custom && (select5 === i) && <div className='mr-1 h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {heightCustom && <div className='w-full mt-2'>
                                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                                    {t('CustomCard_height')}
                                                </label>
                                                <div className="mt-1">
                                                    <input id="name" name="name" type="text" value={ parseFloat(chartData.height) } onChange={ (e)=>{
                                                        setChartData({ ...chartData, height: e.target.value, heightType: 'custom' } ) } } autoComplete="name" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                    <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="name"/></div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className='flex gap-[20px] sm:flex-row flex-col'>
                                        <div className='w-full'>
                                            <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Card_title')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="name" name="name" type="name" value={ values.name } onChange={ handleChange } autoComplete="name" required="" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="name"/></div>
                                            </div>
                                        </div>
                                        <div className='w-full relative'>
                                            <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Unit_Default')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="unit" name='unit' placeholder='' value={ values.unit } onChange={ handleChange } type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="unit"/></div>
                                            </div>
                                        </div>
                                        <div className='w-full relative'>
                                            <label htmlFor="base_value" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Base_value')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="base_value" name='base_value' placeholder='' value={ values.base_value } onChange={ handleChange } type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="base_value"/></div>
                                            </div>
                                        </div>
                                        <div className='w-full relative'>
                                            <label htmlFor="order" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Order')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="order" name='order' placeholder='' value={ values.order } onChange={ handleChange } type="number" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="order"/></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex gap-[20px] sm:flex-row flex-col'>
                                        <div className='w-full'>
                                            <label htmlFor="min" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Min')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="min" name="min" type="number" required="" value={ values.min } onChange={ handleChange } className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="min"/></div>
                                            </div>
                                        </div>
                                        <div className='w-full relative'>
                                            <label htmlFor="max" className="block text-sm font-medium text-[#5a7bad]">
                                                {t('Max')}
                                            </label>
                                            <div className="mt-1">
                                                <input id="max" name='max' placeholder='' type="number" value={ values.max } onChange={ handleChange } className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" />
                                                <div className='text-[red] text-[12px] mt-2'><ErrorMessage name="max"/></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                            {t('Group_As')}
                                        </label>
                                        <div className='grid xl:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                            {jsonData && jsonData.group.map((val, i) => {
                                                return (
                                                    <div key={ i } onClick={ () => {setSelect3(i); setChartData({ ...chartData, group: val.esVal })} } >
                                                        <div className={ `${ select3 === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-2 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                            <h4 className={ `${ select3 === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.value}</h4>
                                                            {select3 === i && <div className='mr-1 h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                            {t('Mode')}
                                        </label>
                                        <div className='grid xl:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mt-2 gap-4'>
                                            {jsonData && jsonData.mode.map((val, i) => {
                                                return (
                                                    <div key={ i } onClick={ () => {setSelect4(i); setChartData({ ...chartData, mode: val.value })} }>
                                                        <div className={ `${ select4 === i ? 'text-[#599bfb] shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] border-[#599bfb]' : 'text-[#5a7bad] border-[#5a7bad]' } flex justify-between items-center p-2 bg-white border-[2px] rounded-xl cursor-pointer focus:outline-none` }>
                                                            <h4 className={ `${ select4 === i ? 'text-[#599bfb]' : 'text-[#5a7bad]' } font-semibold` }>{val.value}</h4>
                                                            {select4 === i && <div className='mr-1 h-[10px] w-[10px] rounded-xl bg-[#599bfb]'></div>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <button type='submit' className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>
                                        {t('Save')}
                                    </button>
                                    <GroupList groupArray={ groupArray } setGroupArray={ setGroupArray }/>

                                    <button type='button' onClick={ handleOnclickGroup } className='py-[7px] w-[200px] font-semibold rounded-lg bg-[#599bfb] text-white mt-[20px] px-[10px] flex justify-center'>
                                        {t('Add_Device_Group')}
                                    </button>

                                    <button type='submit' className='py-[7px] w-[150px] font-semibold rounded-lg bg-[#599bfb] text-white ml-auto px-[10px] flex justify-center'>
                                        {t('Save')}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik> : null }
            </div>

        </div>
    )
}

export default AddCard