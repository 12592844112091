import { AgChartsReact } from 'ag-charts-react'
import { toast } from 'react-toastify'
import { API } from '../../api'
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import BarLoader from 'react-spinners/BarLoader';

const BarchartDemo = ({ id, dashboardId, refresh, toDate, fromDate, setSelectedCards, selectedCards, selectingCards, val }) => {
    const [ options, setOptions ] = useState({})
    const [ loading, setLoading ] = useState(true)
    const [ dateTooLong, setDateTooLong ] = useState(false)
    const [ isCheck, setIsChecked ] = useState(false)

    const style = {
        width: 'auto',
        margin:'auto'
    };

    const getHitsScore = async (loading) => {

        try{
            const toDate = moment(localStorage.getItem('toDate'));
            const fromDate = moment(localStorage.getItem('fromDate'));
            const diff = toDate.diff(fromDate, 'days')
            if(diff > 3 && val.groups[ 0 ].devices[ 0 ].group_as == '1m') {
                setDateTooLong(true)
                setLoading(false)
            }
            else if (localStorage.getItem('toDate') == null && localStorage.getItem('fromDate') == null && val.groups[ 0 ].devices[ 0 ].group_as == '1m'){
                setDateTooLong(true)
                setLoading(false)
            }
            else{
                setDateTooLong(false)
                setLoading(loading)
                const data = {
                    method: 'POST',
                    data : {
                        _id : dashboardId,
                        cardId : id,
                        fromDate,
                        toDate
                    }
                }
                const chartAPI = await API('/chartdata', data)
                console.log('##chartAPI', chartAPI)
                const chartData = chartAPI.chartData
                for (const [ index, d ] of chartData.data.entries()) {
                    chartData.data[ index ].time = moment.utc(('' + d.time).replace('GMT', '')).local().format('DD MMM YYYY')
                }
                console.log('##chartData', chartData)
                setOptions(chartData);
                setLoading(false)
            }
        }catch (error) {
            toast.error(error, { autoClose: 5000 })
        }

    }

    useEffect(() => {
        if ((fromDate !== '' && toDate !== '') || (fromDate == '' && toDate == '')){
            getHitsScore(true)
            const interval = setInterval(() => {
                if(refresh){
                    getHitsScore(false)
                }
            }, 10000)
            return () => clearInterval(interval);
        }
    }, [ fromDate, toDate ])

    useEffect(() => {
        if (!selectingCards){
            setIsChecked(false)
        }
    }, [selectingCards])

    useEffect(() => {
        for(const val of selectedCards){
            if(val._id === id){
                setIsChecked(true)
            }
        }
    }, [selectedCards])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-[35vh]">
                <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
            </div>
        )
    }
    else if(dateTooLong){
        return<div>
            <p className='text-center text-xl font-medium text-[#5a7bad]'>Date range for 1 minute interval should be within 4 days.</p>
        </div>
    }
    else
        return (
            <div className='chart h-[35vh]' style={ style }>
                {selectingCards && <input type="checkbox" className="absolute top-2 left-2" onChange={ () => setSelectedCards([ ...selectedCards, val ]) } checked={ isCheck }/>}
                <AgChartsReact options={ options } />
            </div>
        )
}

export default BarchartDemo